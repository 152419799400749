import React, { useState, useRef, useEffect } from 'react';
import './Helper.css';

const Helper = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [currentTypingIndex, setCurrentTypingIndex] = useState(-1);
  const messagesEndRef = useRef(null);
  const MESSAGE_LIMIT = 10;
  const [isLimitReached, setIsLimitReached] = useState(false);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    if (currentTypingIndex >= 0 && currentTypingIndex < messages.length) {
      const message = messages[currentTypingIndex];
      if (message.role === 'assistant' && message.displayedContent !== message.content) {
        const timer = setTimeout(() => {
          setMessages(prev => prev.map((msg, idx) => 
            idx === currentTypingIndex
              ? { ...msg, displayedContent: msg.content.slice(0, (msg.displayedContent?.length || 0) + 1) }
              : msg
          ));
        }, 20);
        return () => clearTimeout(timer);
      } else if (message.displayedContent === message.content) {
        setCurrentTypingIndex(prev => prev + 1);
      }
    }
  }, [messages, currentTypingIndex]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!input.trim() || isLimitReached) return;

    const userMessage = { role: 'user', content: input, displayedContent: input };
    setInput('');

    if (messages.length >= MESSAGE_LIMIT * 2 - 1) {
      const limitMessage = { 
        role: 'assistant', 
        content: "You've reached the 10-message limit. Please wait for an hour before asking more questions.",
        displayedContent: ''
      };
      setMessages([...messages, userMessage, limitMessage]);
      setCurrentTypingIndex(messages.length + 1);
      setIsLimitReached(true);
      setTimeout(() => {
        setIsLimitReached(false);
        setMessages([]);
      }, 3600000); // Reset after 1 hour (3600000 ms)
    } else {
      setMessages(prev => [...prev, userMessage]);
      setIsLoading(true);

      try {
        const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/query', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ question: input }),
        });

        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        if (data && data.answer) {
          const assistantMessage = { role: 'assistant', content: data.answer, displayedContent: '' };
          setMessages(prev => [...prev, assistantMessage]);
          setCurrentTypingIndex(messages.length + 1);
        } else {
          throw new Error("Unexpected response format");
        }
      } catch (error) {
        console.error('Error:', error);
        const errorMessage = { 
          role: 'assistant', 
          content: `I apologize, but I encountered an error while processing your request. Please try again later or contact support if the problem persists.`, 
          displayedContent: '' 
        };
        setMessages(prev => [...prev, errorMessage]);
        setCurrentTypingIndex(messages.length + 1);
      } finally {
        setIsLoading(false);
      }
    }
  };

  const messageCount = Math.floor(messages.length / 2);

  return (
    <div className="helper-container">
      <h2 className="helper-title">Robo Advisor</h2>
      <div className="chat-container">
        {messages.map((message, index) => (
          <div key={index} className={`message ${message.role}`}>
            <div className="message-content">{message.displayedContent}</div>
          </div>
        ))}
        {isLoading && (
          <div className="message assistant">
            <div className="message-content">
              <div className="typing-indicator">
                <span></span>
                <span></span>
                <span></span>
              </div>
            </div>
          </div>
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSubmit} className="input-form">
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder={isLimitReached 
            ? "Message limit reached. Please wait for an hour." 
            : `Ask about courses, professors, or GPAs... (${messageCount}/${MESSAGE_LIMIT} messages)`}
          disabled={isLoading || isLimitReached}
        />
        <button type="submit" disabled={isLoading || isLimitReached}>
          <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round">
            <line x1="22" y1="2" x2="11" y2="13"></line>
            <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
          </svg>
        </button>
      </form>
    </div>
  );
};

export default Helper;