import React, { useState, useEffect, useRef } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import './SearchBar.css';

const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState({ courses: [], professors: [] });
  const [loading, setLoading] = useState(false);
  const [isSearchActive, setIsSearchActive] = useState(false);
  const searchRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (searchRef.current && !searchRef.current.contains(event.target)) {
        setIsSearchActive(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (searchQuery.trim() === '') {
      setSearchResults({ courses: [], professors: [] });
      return;
    }

    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/search?query=${encodeURIComponent(searchQuery)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        console.log('Search results:', data);
        setSearchResults({
          courses: data.courses,
          professors: data.professors
        });
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchQuery]);

  const handleItemClick = (item, type) => {
    if (type === 'course') {
      window.location.href = `/course/${item.course_code}`;
    } else if (type === 'professor') {
      const professorName = `${item.first_name}-${item.last_name}`;
      window.location.href = `/professor/${professorName}`;
    }
    setIsSearchActive(false);
  };

  const handleSearchFocus = () => {
    setIsSearchActive(true);
  };

  const handleOverlayClick = () => {
    setIsSearchActive(false);
  };

  const highlightMatch = (text, query) => {
    if (!query) return text;
    const words = query.toLowerCase().split(/\s+/);
    let result = text;
    words.forEach(word => {
      if (word.length > 0) {
        const regex = new RegExp(`(${word})`, 'gi');
        result = result.replace(regex, '<mark>$1</mark>');
      }
    });
    return <span dangerouslySetInnerHTML={{ __html: result }} />;
  };

  const fuzzyMatch = (text, query) => {
    const words = query.toLowerCase().split(' ');
    return words.every(word => {
      const regex = new RegExp(word.split('').join('.*'), 'i');
      return regex.test(text.toLowerCase());
    });
  };

  const filterAndSortResults = (items, query, getSearchString) => {
    return items
      .filter(item => fuzzyMatch(getSearchString(item), query))
      .sort((a, b) => {
        const aScore = getSearchString(a).toLowerCase().indexOf(query.toLowerCase());
        const bScore = getSearchString(b).toLowerCase().indexOf(query.toLowerCase());
        return aScore - bScore;
      });
  };

  const filteredCourses = filterAndSortResults(
    searchResults.courses,
    searchQuery,
    (course) => `${course.course_code} ${course.course_name || ''}`
  );

  const filteredProfessors = filterAndSortResults(
    searchResults.professors,
    searchQuery,
    (professor) => `${professor.first_name} ${professor.last_name}`
  );

  const formatName = (name) => {
    return name
      .toLowerCase()
      .split(' ')
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(' ');
  };

  return (
    <div className={`search-bar-header ${isSearchActive ? 'search-active' : ''}`}>
      <div className="header-top">
        <div className="branding">
          <h1 className="brand-name">BearPath</h1>
          <p className="brand-description">Explore student-rated professors, selective grade distributions for any past courses at the University of Alberta</p>
        </div>
        <div className="navigation-buttons">
          <div className="primary-nav">
            <Link to="/" className="nav-button primary">🏠 HOME</Link>
            <Link to="/SIM" className="nav-button primary">🎯 DEGREE MAPPER</Link>
            <Link to="/ai" className="nav-button primary">🤖 ADVISOR</Link>
          </div>
          <div className="secondary-nav">
            <Link to="/popular" className="nav-button">🔥 POPULAR</Link>
            <Link to="/random" className="nav-button">🎲 RANDOM</Link>
            <Link to="/faq" className="nav-button">🤔 FAQ</Link>
            <Link to="/about" className="nav-button">👋 ABOUT</Link>
          </div>
        </div>
      </div>
  
      <div className="search-bar-container" ref={searchRef}>
        <div className="search-bar-wrapper">
          <input
            type="text"
            className="search-bar"
            placeholder=" "
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            onFocus={handleSearchFocus}
          />
          <span className="search-text">🔍 Search</span>
        </div>

        <div className="search-bar-underlay">
          <div className="search-bar-underlay-text">Please enter course or instructor. Example: MATH 100, John Doe, Calculus.</div>
        </div>
  
        {isSearchActive && (
          <div className="search-results">
            {loading ? (
              <div className="loading-bar-container">
                <div className="loading-bar"></div>
              </div>
            ) : (
              <>
                {filteredCourses.length > 0 && (
                  <div className="courses-section">
                    <h2>📚 Courses:</h2>
                    <div className="result-list">
                    {filteredCourses.map((course, index) => (
                      <div key={index} onClick={() => handleItemClick(course, 'course')} className="clickable-result">
                        {highlightMatch(`${course.course_code}: ${course.course_name}`, searchQuery)}
                      </div>
                    ))}
                    </div>
                  </div>
                )}

                {filteredProfessors.length > 0 && (
                  <div className="professors-section">
                    <h2>🧑‍🏫 Instructors:</h2>
                    <div className="result-list">
                    {filteredProfessors.map((professor, index) => (
                      <div key={index} onClick={() => handleItemClick(professor, 'professor')} className="clickable-result">
                        {highlightMatch(`${formatName(professor.first_name)} ${formatName(professor.last_name)}`, searchQuery)}
                      </div>
                    ))}
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        )}
      </div>
      <div className="overlay" onClick={handleOverlayClick}></div>
    </div>
  );
};

export default SearchBar;