import React, { useState, useEffect } from 'react';
import './FAQpage.css';

const MobileDropdown = ({ selectedFaq, setSelectedFaq, isOpen, setIsOpen }) => {
  const faqCategories = [
    "Data Accuracy",
    "Rating Sources",
    "Purpose & Ethics",
    "Grade Interpretation",
    "University Affiliation"
  ];

  const handleSelect = (faq) => {
    setSelectedFaq(faq);
    setIsOpen(false);
  };

  return (
    <div className="mobile-dropdown">
      <div 
        className={`mobile-dropdown-header ${isOpen ? 'active' : ''}`} 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedFaq}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>▼</span>
      </div>
      {isOpen && (
        <div className="mobile-dropdown-content">
          {faqCategories.map((category) => (
            <div 
              key={category}
              className={`mobile-dropdown-item ${selectedFaq === category ? 'active' : ''}`}
              onClick={() => handleSelect(category)}
            >
              {category}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const FAQPage = () => {
  const [selectedFaq, setSelectedFaq] = useState("Data Accuracy");
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


   const handleFaqChange = (newFaq) => {
    setIsAnimating(true);
    setSelectedFaq(newFaq);
   
    if (isMobile) {
      setMobileDropdownOpen(false);
    }
  };

  const getFAQContent = () => {
    switch(selectedFaq) {
      case "Data Accuracy":
        return (
            <div className="faq-content">
                <h2>Is all the rating and grade data accurate?</h2>
                <p>BearPath contains two types of data: SPOT ratings and grade (GPA) data.</p>
                
                <p>SPOT (Student Perspectives of Teaching) ratings are obtained directly from the University of Alberta's official survey system. These ratings are 100% authentic and unchanged from their source, as they are provided directly by the university after each term.</p>
                
                <p>Grade data is crowdsourced from student submissions. Here's how we maintain grade data accuracy:</p>
                <ul>
                    <li>Students must provide screenshots of their official grade reports for verification</li>
                    <li>Each course's data is verified against multiple independent student submissions</li>
                    <li>We collect submissions through secure Google Forms with institutional email verification</li>
                    <li>Every submission undergoes a verification process before being added to the database</li>
                    <li>Grades are only displayed after receiving at least 5 verified submissions for a course section</li>
                </ul>
                
                <p>Important notes about grade data:</p>
                <ul>
                    <li>There might be discrepancies between different sections of the same course due to various factors including teaching styles and course adjustments</li>
                    <li>A limited number of section submissions may not represent the overall difficulty of a course - use this data as a reference only</li>
                    <li>Higher-level courses (some 300-level, most 400-level, and graduate courses) often have limited or no grade data due to smaller class sizes</li>
                    <li>Grade data should not be the primary factor in your course selection decisions</li>
                </ul>
                
                <p>For transparency, we display the verified submissions' gpa data for each course section, helping you understand the data's representation. Remember that grade data is meant to serve as a reference point rather than a decisive factor in your course selection.</p>
                </div>
        );
      case "Rating Sources":
        return (
          <div className="faq-content">
            <h2>Where do the ratings come from?</h2>
            <p>Our ratings come directly from the University of Alberta's official Student Perspectives of Teaching (SPOT) survey system. Here's what you need to know:</p>
            <ul>
              <li>SPOT surveys are conducted during the last two weeks of each course</li>
              <li>They include standardized questions about teaching effectiveness</li>
              <li>All responses are anonymous and verified through the university's system</li>
              <li>Ratings include both numerical scores and written feedback</li>
              <li>Data is updated within 20 working days after course completion</li>
            </ul>
            <p>The SPOT system ensures data integrity through:</p>
            <ul>
              <li>Secure student authentication</li>
              <li>Anonymous response collection</li>
              <li>Standardized evaluation criteria</li>
              <li>University-verified results</li>
            </ul>
          </div>
        );
      case "Purpose & Ethics":
        return (
            <div className="faq-content">
            <h2>What is the purpose of BearPath?</h2>
            
            <p>BearPath's primary mission is to provide transparent, unbiased information to students navigating through one of the most challenging periods of their lives - university education. Every course decision can impact your academic journey, and we believe students deserve access to reliable data to make informed choices.</p>
            
            <p>A fundamental principle in designing BearPath was to move away from subjective ratings and focus on standardized data. Here's why:</p>
            <ul>
              <li>Traditional rating platforms can be skewed by emotional responses - students might leave multiple negative ratings after a poor grade or excessive praise due to personal bias</li>
              <li>SPOT surveys provide standardized, structured feedback from every student in the class</li>
              <li>All students complete the same questions, ensuring consistency across courses and instructors</li>
              <li>Responses are collected during the course, not after grades are released, reducing grade-based bias</li>
            </ul>
            
            <p>By utilizing SPOT data, we ensure all information remains open to interpretation. You won't find lists like "Top 10 Easiest Professors" or "Courses to Avoid" on BearPath. Instead, we present standardized data that allows students to:</p>
            <ul>
              <li>Review consistent metrics across all courses</li>
              <li>Compare courses using the same evaluation criteria</li>
              <li>Make decisions based on comprehensive class feedback rather than individual experiences</li>
              <li>Access historical course data without subjective interpretations</li>
            </ul>
            
            <p>Regarding data representation: as all SPOT surveys use standardized questions with agree/disagree responses, the data speaks for itself. While some instructors may not appreciate how the data represents them, we cannot alter standardized university survey results. The University of Alberta, as a public institution, is obligated to maintain transparency in its operations. This transparency naturally invites scrutiny and accountability, which ultimately benefits the entire academic community.</p>
          </div>
        );
      case "Grade Interpretation":
        return (
            <div className="faq-content">
            <h2>How should I interpret the grade data?</h2>
            
            <p>All grade data on BearPath is for reference only. Course grading varies significantly based on multiple factors:</p>
            <ul>
              <li>Different faculties and departments may have varying grade distribution policies</li>
              <li>Individual instructors may adjust grade curves based on class performance</li>
              <li>Course content and assessments can change between terms</li>
              <li>Class averages can fluctuate based on cohort performance</li>
            </ul>
           
            <p>The University of Alberta uses the following grading system:</p>
            <table className="grade-table">
              <thead>
                <tr>
                  <th>Descriptor</th>
                  <th>Letter Grade</th>
                  <th>Grade Point Value</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td rowSpan="3">Excellent</td>
                  <td>A+</td>
                  <td>4.0</td>
                </tr>
                <tr>
                  <td>A</td>
                  <td>4.0</td>
                </tr>
                <tr>
                  <td>A-</td>
                  <td>3.7</td>
                </tr>
                <tr>
                  <td rowSpan="3">Good</td>
                  <td>B+</td>
                  <td>3.3</td>
                </tr>
                <tr>
                  <td>B</td>
                  <td>3.0</td>
                </tr>
                <tr>
                  <td>B-</td>
                  <td>2.7</td>
                </tr>
                <tr>
                  <td rowSpan="3">Satisfactory</td>
                  <td>C+</td>
                  <td>2.3</td>
                </tr>
                <tr>
                  <td>C</td>
                  <td>2.0</td>
                </tr>
                <tr>
                  <td>C-</td>
                  <td>1.7</td>
                </tr>
                <tr>
                  <td>Poor</td>
                  <td>D+</td>
                  <td>1.3</td>
                </tr>
                <tr>
                  <td>Minimal Pass</td>
                  <td>D</td>
                  <td>1.0</td>
                </tr>
                <tr>
                  <td>Failure</td>
                  <td>F</td>
                  <td>0.0</td>
                </tr>
              </tbody>
            </table>
            <p className="table-source">Source: <a href="https://www.ualberta.ca/en/registrar/examinations/assessment-and-grading/grading-system-explained.html" className="source-link">University of Alberta Grading System</a></p>
           
            <p>Important considerations when interpreting grades:</p>
            <ul>
              <li>Course grades may be curved or adjusted based on class performance</li>
              <li>Prerequisites often require minimum letter grades (not just passing grades)</li>
              <li>Some faculties may have different grade requirements for program continuation</li>
              <li>Graduate programs and professional schools often have minimum GPA requirements</li>
            </ul>
           
            <p>Best practices for using grade data:</p>
            <ul>
              <li>Always consult current course syllabi for specific grading criteria</li>
              <li>Discuss grading policies directly with course instructors</li>
              <li>Consider both grade distributions and SPOT ratings for a complete picture</li>
              <li>Remember that historical grades don't predict future performance</li>
              <li>Focus on learning outcomes rather than just grade averages</li>
            </ul>
           
            <p>Note that grade distributions can vary significantly between:</p>
            <ul>
              <li>Different sections of the same course</li>
              <li>Different terms or academic years</li>
              <li>Required courses versus electives</li>
              <li>Lower-level versus upper-level courses</li>
              <li>Different instructors teaching the same course</li>
            </ul>
           </div>
        );
      case "University Affiliation":
        return (
          <div className="faq-content">
            <h2>Is BearPath affiliated with the University of Alberta?</h2>
            <p>No. BearPath is not officially affiliated with, endorsed by, or connected to the University of Alberta. We are:</p>
            <ul>
              <li>An independent platform created by students, for students</li>
              <li>Not associated with university administration</li>
              <li>Committed to transparency about our independent status</li>
              <li>Using publicly available data and verified student submissions</li>
            </ul>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="faq-page">
      {isMobile ? (
        <MobileDropdown 
          selectedFaq={selectedFaq}
          setSelectedFaq={setSelectedFaq}
          isOpen={mobileDropdownOpen}
          setIsOpen={setMobileDropdownOpen}
        />
      ) : (
        <div className="sidebar">
          <h2>FAQ Categories</h2>
          {["Data Accuracy", "Rating Sources", "Purpose & Ethics", 
            "Grade Interpretation", "University Affiliation"].map(faq => (
            <button 
              key={faq}
              className={selectedFaq === faq ? 'active' : ''}
              onClick={() => handleFaqChange(faq)}
            >
              {faq}
            </button>
          ))}
        </div>
      )}

<div className="content">
        <div 
          key={selectedFaq} // This forces React to remount the content
          className="faq-content"
          onAnimationEnd={() => setIsAnimating(false)}
        >
          {getFAQContent()}
        </div>
      </div>
    </div>
  );
};

export default FAQPage;