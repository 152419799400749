import React, { useState, useEffect, useRef } from 'react';
import './CourseSimulator.css';
import { majorConfig, minorConfig } from './simulator/faculty_of_science/programConfig.js';
import CourseSimulatorDetails from './CourseSimulatorDetails';

const CustomSelect = ({ options, value, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-select" ref={selectRef}>
      <label>{label}</label>
      <div 
        className={`custom-select-selected ${isOpen ? 'active' : ''}`}
        onClick={handleToggle}
      >
        {value}
      </div>
      {isOpen && (
        <div className="custom-select-options">
          {options.map((option) => (
            <div
              key={option}
              className="custom-select-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const CourseSimulator = () => {
    const [selectedMajor, setSelectedMajor] = useState('Computing Science');
    const [selectedMinor, setSelectedMinor] = useState('Mathematics');
    const [MajorComponent, setMajorComponent] = useState(null);
    const [MinorComponent, setMinorComponent] = useState(null);
    const [showDetails, setShowDetails] = useState(false);
    const [warning, setWarning] = useState('');

    useEffect(() => {
        if (majorConfig[selectedMajor]) {
            majorConfig[selectedMajor].component().then((module) => {
                setMajorComponent(() => module.default);
            });
        }
    }, [selectedMajor]);

    useEffect(() => {
        setMinorComponent(null);
        if (minorConfig[selectedMinor]) {
            minorConfig[selectedMinor].component().then((module) => {
                setMinorComponent(() => module.default);
            });
        }
    }, [selectedMinor]);

    useEffect(() => {
        if (selectedMajor === selectedMinor) {
            setWarning('Warning: Major and minor cannot be the same.');
        } else {
            setWarning('');
        }
    }, [selectedMajor, selectedMinor]);

    const handleContinue = () => {
        if (selectedMajor !== selectedMinor) {
            setShowDetails(true);
        }
    };

    return (
        <div className="simulator">
            <h1>Course Requirement Simulator</h1>
            {!showDetails ? (
                <div className="simulator-selection">
                    <div className="selectors">
                        <CustomSelect
                            options={Object.keys(majorConfig)}
                            value={selectedMajor}
                            onChange={setSelectedMajor}
                            label="Major"
                        />
                        <CustomSelect
                            options={Object.keys(minorConfig)}
                            value={selectedMinor}
                            onChange={setSelectedMinor}
                            label="Minor"
                        />
                    </div>
                    {warning && <div className="warning">{warning}</div>}
                    <button 
                        className="continue-button" 
                        onClick={handleContinue}
                        disabled={selectedMajor === selectedMinor}
                    >
                        Continue
                    </button>
                </div>
            ) : (
                <CourseSimulatorDetails 
                    MajorComponent={MajorComponent}
                    MinorComponent={MinorComponent}
                    selectedMajor={selectedMajor}
                    selectedMinor={selectedMinor}
                />
            )}
        </div>
    );
};

export default CourseSimulator;