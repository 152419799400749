// IndexPage.js

import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './IndexPage.css';
import DataUpload from './DataUpload';

const IndexPage = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [searchResults, setSearchResults] = useState({ courses: [], professors: [] });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const apiUrl = "https://normal-ada-course-pilot-f32b853e.koyeb.app/api/upload"; // Define your API URL here

  useEffect(() => {
    if (searchQuery.trim() === '') {
      // Clear results if search query is empty
      setSearchResults({ courses: [], professors: [] });
      return;
    }

    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/search?query=${encodeURIComponent(searchQuery)}`);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setSearchResults(data);
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchSearchResults();
  }, [searchQuery]);

  // Helper function to capitalize only the first letter of a string
  const capitalizeFirstLetter = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  // Helper function to format the full name
  const formatName = (firstName, lastName) => {
    return `${capitalizeFirstLetter(firstName)} ${capitalizeFirstLetter(lastName)}`;
  };

  // In the handleItemClick function in IndexPage.js
  const handleItemClick = (item, type) => {
    if (type === 'course') {
      navigate(`/course/${item.course_code}`);
    } else if (type === 'professor') {
      const professorName = `${item.first_name}-${item.last_name}`;
      navigate(`/professor/${professorName}`);
    }
  };

  return (
    <div className="index-page">
      <header>
        <h1>Course and Professor Search</h1>
        <p>Find detailed information about courses and professors</p>
      </header>

      <main>
        {/* Updated Search Bar with Increased Size */}
        <input
          type="text"
          className="search-bar large"  // Add a new class for large styling
          placeholder="Search for a course or professor"
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        {loading ? (
          <div className="loading-screen">
            <div className="spinner"></div>
            <p>Loading data...</p>
          </div>
        ) : (
          (searchResults.courses.length > 0 || searchResults.professors.length > 0) && (
            <div className="search-results">
              {/* Display Courses in a Table */}
              {searchResults.courses.length > 0 && (
                <div className="courses-section">
                  <h2>Courses</h2>
                  <table className="results-table">
                    <thead>
                      <tr>
                        <th>Course Code</th>
                        <th>Course Name</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults.courses.slice(0, 10).map((course, index) => (
                        <tr key={index} onClick={() => handleItemClick(course, 'course')} className="clickable-row">
                          <td>{course.course_code}</td>
                          <td>{course.course_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}

              {/* Display Professors in a Table */}
              {searchResults.professors.length > 0 && (
                <div className="professors-section">
                  <h2>Professors</h2>
                  <table className="results-table">
                    <thead>
                      <tr>
                        <th>Professor Name</th>
                        <th>Department</th>
                      </tr>
                    </thead>
                    <tbody>
                      {searchResults.professors.slice(0, 10).map((professor, index) => (
                        <tr key={index} onClick={() => handleItemClick(professor, 'professor')} className="clickable-row">
                          <td>{formatName(professor.first_name, professor.last_name)}</td>
                          <td>{professor.department_name}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          )
        )}

        {/* DEV TESTING SECTION */}
        <h2>----------------------------DEV TESTING ONLY----------------------------</h2>
        <div className="data-upload-buttons">
          <Link to="/upload">
            <button>Manual Upload</button>
          </Link>
          <Link to="/dataupload">
            <button>Bulk Upload</button>
          </Link>
        </div>

        {/* Include the DataUpload component directly for example purposes */}
        <div>
          <h2>Bulk Data Upload</h2>
          <DataUpload apiUrl={apiUrl} /> {/* Pass the apiUrl as a prop */}
        </div>
      </main>

      <footer>
        <p>&copy; 2024 Your Application. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default IndexPage;
