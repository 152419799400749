import React, { useState, useEffect } from 'react';
import './Simulator.css';

const Simulator = () => {
    const [requirements, setRequirements] = useState({
        facultyRequirements: null,
        majorRequirements: {},
        minorRequirements: {},
        completedCourses: [],
        juniorCore: {
            english: [],
            physicalSciences: [],
            mathSciences: [],
            labSciences: []
        },
        artsOption: {
            title: "Arts Option",
            neededCourses: 6,
            completedCourses: [],
        },
    });
    const [searchResults, setSearchResults] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedMajor, setSelectedMajor] = useState('Computing Science');
    const [selectedMinor, setSelectedMinor] = useState('Mathematics');
    const [allCourses, setAllCourses] = useState([]);
    const [juniorCoreRequirements, setJuniorCoreRequirements] = useState({});

    useEffect(() => {
        fetchAllCourses();
        fetchRequirements();
        fetchJuniorCoreRequirements();
    }, [selectedMajor, selectedMinor]);

    const fetchAllCourses = async () => {
        try {
            const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/coursereq/courses');
            if (!response.ok) throw new Error('Failed to fetch all courses');
            const data = await response.json();
            setAllCourses(data);
        } catch (error) {
            console.error('Error fetching all courses:', error);
            setError('Failed to fetch courses. Please try again.');
        }
    };

    const fetchRequirements = async () => {
        try {
            const encodedMajor = encodeURIComponent(selectedMajor);
            const encodedMinor = encodeURIComponent(selectedMinor);
            const majorUrl = `https://normal-ada-course-pilot-f32b853e.koyeb.app/api/requirements/${encodedMajor}`;
            const minorUrl = `https://normal-ada-course-pilot-f32b853e.koyeb.app/api/requirements/${encodedMinor}/minor`;
    
            const [majorResponse, minorResponse] = await Promise.all([
                fetch(majorUrl),
                fetch(minorUrl)
            ]);
    
            if (!majorResponse.ok || !minorResponse.ok) {
                throw new Error('Failed to fetch requirements');
            }
    
            const majorData = await majorResponse.json();
            const minorData = await minorResponse.json();
    
            // Process minor requirements to remove duplicates
            const processedMinorRequirements = {};
            for (const [group, courses] of Object.entries(minorData.minorRequirements)) {
                processedMinorRequirements[group] = Array.from(new Set(courses.map(JSON.stringify))).map(JSON.parse);
            }
    
            setRequirements(prev => ({
                ...prev,
                facultyRequirements: majorData.facultyRequirements,
                majorRequirements: majorData.majorRequirements,
                minorRequirements: processedMinorRequirements,
            }));
        } catch (error) {
            console.error('Error fetching requirements:', error);
            setError('Failed to fetch requirements. Please try again.');
        }
    };

    const fetchJuniorCoreRequirements = async () => {
        try {
            const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/junior-core-requirements');
            if (!response.ok) throw new Error('Failed to fetch Junior Core requirements');
            const data = await response.json();
            setJuniorCoreRequirements(data);
        } catch (error) {
            console.error('Error fetching Junior Core requirements:', error);
            setError('Failed to fetch Junior Core requirements. Please try again.');
        }
    };

    useEffect(() => {
        const fetchCourses = async () => {
            if (!searchQuery) {
                setSearchResults([]);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/coursereq/search?query=${encodeURIComponent(searchQuery)}`);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setSearchResults(data.courses);
            } catch (error) {
                console.error('Error fetching courses:', error);
                setError('Failed to fetch courses. Please try again.');
                setSearchResults([]);
            } finally {
                setLoading(false);
            }
        };

        const debounceTimer = setTimeout(() => {
            fetchCourses();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery]);

    const removeCourseFromJuniorCore = (category, courseToRemove) => {
        setRequirements(prev => {
            const updatedRequirements = { ...prev };

            // Map the category names to match the state structure
            const categoryMapping = {
                'English': 'english',
                'Physical Science': 'physicalSciences',
                'Math Science': 'mathSciences',
                'Laboratory Science': 'labSciences'
            };

            const juniorCoreCategory = categoryMapping[category];

            if (juniorCoreCategory && updatedRequirements.juniorCore && updatedRequirements.juniorCore[juniorCoreCategory]) {
                // Remove course from the junior core category
                updatedRequirements.juniorCore[juniorCoreCategory] = updatedRequirements.juniorCore[juniorCoreCategory].filter(
                    course => course.course_letter !== courseToRemove.course_letter || course.course_number !== courseToRemove.course_number
                );

                // Remove course from completedCourses array
                updatedRequirements.completedCourses = updatedRequirements.completedCourses.filter(
                    course => course.course_letter !== courseToRemove.course_letter || course.course_number !== courseToRemove.course_number
                );
            } else {
                console.error(`Category ${category} not found in juniorCore`);
            }

            return updatedRequirements;
        });
    };

    const removeCourseFromMajorRequirements = (group, courseToRemove) => {
        setRequirements(prev => {
            const updatedRequirements = { ...prev };
            updatedRequirements.majorRequirements[group] = updatedRequirements.majorRequirements[group].map(course => {
                if (course.course_letter === courseToRemove.course_letter && course.course_number === courseToRemove.course_number) {
                    return { ...course, completed: false };
                }
                return course;
            });
    
            // Remove course from completedCourses array
            updatedRequirements.completedCourses = updatedRequirements.completedCourses.filter(
                course => course.course_letter !== courseToRemove.course_letter || course.course_number !== courseToRemove.course_number
            );
    
            return updatedRequirements;
        });
    };

    const removeCourseFromMinorRequirements = (group, courseToRemove) => {
        setRequirements(prev => {
            const updatedRequirements = { ...prev };
            updatedRequirements.minorRequirements[group] = updatedRequirements.minorRequirements[group].map(course => {
                if (course.course_letter === courseToRemove.course_letter && course.course_number === courseToRemove.course_number) {
                    return { ...course, completed: false };
                }
                return course;
            });
    
            // Remove course from completedCourses array
            updatedRequirements.completedCourses = updatedRequirements.completedCourses.filter(
                course => course.course_letter !== courseToRemove.course_letter || course.course_number !== courseToRemove.course_number
            );
    
            return updatedRequirements;
        });
    };

    const removeCourseFromArtsOption = (courseToRemove) => {
        setRequirements(prev => {
            const updatedRequirements = { ...prev };
            updatedRequirements.artsOption.completedCourses = updatedRequirements.artsOption.completedCourses.filter(
                course => course.course_letter !== courseToRemove.course_letter || course.course_number !== courseToRemove.course_number
            );

            // Remove course from completedCourses array
            updatedRequirements.completedCourses = updatedRequirements.completedCourses.filter(
                course => course.course_letter !== courseToRemove.course_letter || course.course_number !== courseToRemove.course_number
            );

            return updatedRequirements;
        });
    };

    const addCourse = (courseCode) => {
        const splitIndex = courseCode.lastIndexOf(' '); // Get the last space in the string
        const courseLetter = courseCode.slice(0, splitIndex); // Everything before the last space is the course letter
        const courseNumber = courseCode.slice(splitIndex + 1); // Everything after the last space is the course number
        const courseLevel = parseInt(courseNumber);
        
        const course = allCourses.find(c => 
            c.course.course_letter === courseLetter.trim() && 
            c.course.course_number === courseNumber.trim()
        );
    
        if (!course) return;
        //console.log('Course details:', course); // Debug course data
        setRequirements(prev => {
            const updatedRequirements = { ...prev };
            const { completedCourses, majorRequirements, juniorCore, minorRequirements } = updatedRequirements;
    
            // Check if course is already added to the completed courses
            if (completedCourses.some(c => c.course_letter === courseLetter && c.course_number === courseNumber)) {
                return updatedRequirements;
            }
    
            // Add to completed courses
            completedCourses.push({ course_letter: courseLetter, course_number: courseNumber });
    
            let addedToJuniorCore = false;
            let addedToMajor = false;
            let addedToMinor = false;
            let addedToArts = false;
    
            // Check Junior Core requirements and add the course to the appropriate category
            const juniorCoreCategory = Object.keys(juniorCoreRequirements).find(cat =>
                juniorCoreRequirements[cat].some(c =>
                    c.course_letter === courseLetter && c.course_number === courseNumber
                )
            );
    
            if (juniorCoreCategory) {
                switch (juniorCoreCategory) {
                    case 'English':
                        if (juniorCore.english.length < 2) {
                            juniorCore.english.push({ course_letter: courseLetter, course_number: courseNumber });
                            addedToJuniorCore = true;
                        }
                        break;
                    case 'Physical Sciences':
                        if (juniorCore.physicalSciences.length < 2) {
                            juniorCore.physicalSciences.push({ course_letter: courseLetter, course_number: courseNumber });
                            addedToJuniorCore = true;
                        }
                        break;
                    case 'Mathematical Sciences':
                        if (juniorCore.mathSciences.length < 2) {
                            juniorCore.mathSciences.push({ course_letter: courseLetter, course_number: courseNumber });
                            addedToJuniorCore = true;
                        }
                        break;
                    case 'Laboratory Sciences':
                        if (juniorCore.labSciences.length < 2) {
                            juniorCore.labSciences.push({ course_letter: courseLetter, course_number: courseNumber });
                            addedToJuniorCore = true;
                        }
                        break;
                }
            }
    
            // Helper function to check if all mandatory groups are satisfied
            const areMandatoryGroupsSatisfied = () => {
                const mandatoryGroups = ['Mandatory Core', 'Mandatory Group 1', 'Mandatory Group 2', 'Mandatory Group 3', 'Mandatory Group 4'];
                return mandatoryGroups.every(group => {
                    const courses = majorRequirements[group];
                    const completedCourses = courses.filter(course => course.completed);
                    return group === 'Mandatory Group 1' ? completedCourses.length >= 2 : completedCourses.length === courses.length;
                });
            };
    
            // Update Major Requirements
            if (majorRequirements['Mandatory Core']) {
                majorRequirements['Mandatory Core'] = majorRequirements['Mandatory Core'].map(req => {
                    if (req.course_letter === courseLetter && req.course_number === courseNumber) {
                        addedToMajor = true;
                        return { ...req, completed: true };
                    }
                    return req;
                });
            }
    
            if (!addedToMajor && majorRequirements['Mandatory Group 1']) {
                const mandatoryGroup1Courses = ['201', '204', '229', '272', '291'];
                if (courseLetter === 'CMPUT' && mandatoryGroup1Courses.includes(courseNumber)) {
                    const completedCount = majorRequirements['Mandatory Group 1'].filter(req => req.completed).length;
                    if (completedCount < 2) {
                        const courseToUpdate = majorRequirements['Mandatory Group 1'].find(req => 
                            req.course_letter === 'CMPUT' && req.course_number === courseNumber && !req.completed
                        );
                        if (courseToUpdate) {
                            courseToUpdate.completed = true;
                            addedToMajor = true;
                        }
                    }
                }
            }
    
            if (!addedToMajor && majorRequirements['Mandatory Group 2']) {
                const courseLevel = parseInt(courseNumber);
                if (courseLetter === 'CMPUT' && courseLevel >= 400) {
                    const uncompleted = majorRequirements['Mandatory Group 2'].find(req => !req.completed);
                    if (uncompleted) {
                        uncompleted.completed = true;
                        uncompleted.course_letter = courseLetter;
                        uncompleted.course_number = courseNumber;
                        addedToMajor = true;
                    }
                }
            }
    
            if (!addedToMajor && majorRequirements['Mandatory Group 3']) {
                const courseLevel = parseInt(courseNumber);
                if (courseLetter === 'CMPUT' && courseLevel >= 300) {
                    const uncompleted = majorRequirements['Mandatory Group 3'].find(req => !req.completed);
                    if (uncompleted) {
                        uncompleted.completed = true;
                        uncompleted.course_letter = courseLetter;
                        uncompleted.course_number = courseNumber;
                        addedToMajor = true;
                    }
                }
            }
    
            if (!addedToMajor && majorRequirements['Mandatory Group 4']) {
                if (courseLetter === 'CMPUT') {
                    const uncompleted = majorRequirements['Mandatory Group 4'].find(req => !req.completed);
                    if (uncompleted) {
                        uncompleted.completed = true;
                        uncompleted.course_letter = courseLetter;
                        uncompleted.course_number = courseNumber;
                        addedToMajor = true;
                    }
                }
            }
    
            // If all mandatory groups are satisfied and the course hasn't been added to major requirements, add it to the 'rest' category
            // Before adding to Major's 'Rest', ensure the course is not part of Minor
            if (!addedToMinor && !addedToMajor && areMandatoryGroupsSatisfied()) {
                if (!majorRequirements['Rest']) {
                    majorRequirements['Rest'] = [];
                }
                majorRequirements['Rest'].push({
                    course_letter: courseLetter,
                    course_number: courseNumber,
                    completed: true
                });
                addedToMajor = true;
            }

            // Update Minor Requirements (Mathematics)
            if (!addedToMinor && courseLetter === 'MATH') {
                Object.entries(minorRequirements).forEach(([group, courses]) => {
                    courses.forEach(course => {
                        if (course.course_number === courseNumber && !course.completed) {
                            course.completed = true;
                            addedToMinor = true;
                        }
                    });
                });
            }

            // Update Minor Requirements (Mathematics)
            if (!addedToMinor && courseLetter === 'MATH') {
                let addedToSpecificGroup = false;
                Object.entries(minorRequirements).forEach(([group, courses]) => {
                    if (!addedToSpecificGroup) {
                        if (group === 'Mandatory') {
                            courses.forEach(course => {
                                if (course.course_number === courseNumber && !course.completed) {
                                    course.completed = true;
                                    addedToMinor = true;
                                    addedToSpecificGroup = true;
                                }
                            });
                        } else if (group.startsWith('Core')) {
                            const uncompleted = courses.find(course => !course.completed);
                            if (uncompleted) {
                                uncompleted.completed = true;
                                uncompleted.course_letter = courseLetter;
                                uncompleted.course_number = courseNumber;
                                addedToMinor = true;
                                addedToSpecificGroup = true;
                            }
                        }
                    }
                });

                // If not added to any specific group, add to 'Additional Courses'
                if (!addedToSpecificGroup) {
                    if (!minorRequirements['Additional Courses']) {
                        minorRequirements['Additional Courses'] = [];
                    }
                    minorRequirements['Additional Courses'].push({
                        course_letter: courseLetter,
                        course_number: courseNumber,
                        completed: true
                    });
                    addedToMinor = true;
                }
            }

            // Group 3: Choose 1 from MATH 215, MATH 228, MATH 317
            if (minorRequirements['Mandatory Group 3']) {
                const group3 = minorRequirements['Mandatory Group 3'];
                if (!group3.some(course => course.completed) && (courseNumber === '315' || courseNumber === '228' || courseNumber === '317')) {
                    minorRequirements['Mandatory Group 3'] = [...group3, { course_letter: courseLetter, course_number: courseNumber, completed: true }];
                    addedToMinor = true;
                }
            }

            // Update Minor Requirements: Group 4 and Group 5
            // Group 4: 200, 300, 400 level MATH or MA PH courses
            if (!addedToMinor && (courseLetter === 'MATH' || courseLetter === 'MA PH') && courseLevel >= 200 && courseLevel <= 400) {
                if (minorRequirements['Mandatory Group 4']) {
                    const group4 = minorRequirements['Mandatory Group 4'];
                    if (group4.filter(course => course.completed).length < 2) {
                        minorRequirements['Mandatory Group 4'] = [...group4, { course_letter: courseLetter, course_number: courseNumber, completed: true }];
                        addedToMinor = true;
                    }
                }
            }

            // Group 5: 300+ level MATH or MA PH courses
            if (!addedToMinor && courseLevel >= 300) {
                if (minorRequirements['Mandatory Group 5']) {
                    const group5 = minorRequirements['Mandatory Group 5'];
                    if (group5.filter(course => course.completed).length < 2) {
                        minorRequirements['Mandatory Group 5'] = [...group5, { course_letter: courseLetter, course_number: courseNumber, completed: true }];
                        addedToMinor = true;
                    }
                }
            }

            if (!addedToMajor && !addedToMinor && !addedToJuniorCore) {
                // Access the correct path to check if the course belongs to the Arts Option
                const isArtsCourse = course.requirements?.artsOption || false; // Safely check the nested structure
                //console.log('Checking if course is Arts:', isArtsCourse, course); // Debug the correct flag
            
                if (isArtsCourse) {
                    addCourseToArtsOption(courseLetter.trim(), courseNumber.trim());
                    addedToArts = true;
                }
            }

            // Ensure the course doesn't get added to Minor "Rest" if it's already added to a mandatory group (Group 3, 4, 5, etc.)
            if (!addedToMinor && !minorRequirements['Mandatory Group 3'].some(course => course.course_letter === courseLetter && course.course_number === courseNumber)) {
                if (!minorRequirements['Rest']) {
                    minorRequirements['Rest'] = [];
                }
                minorRequirements['Rest'].push({
                    course_letter: courseLetter,
                    course_number: courseNumber,
                    completed: true
                });
                addedToMinor = true;
            }
            return updatedRequirements;
        });
    
        setSearchQuery('');
        setSearchResults([]);
    };

    const addCourseToArtsOption = (courseLetter, courseNumber) => {
        setRequirements(prev => {
            const updatedRequirements = { ...prev };
            const { artsOption } = updatedRequirements;
            // Debugging: log when adding to Arts Option
            console.log("Adding to Arts Option:", courseLetter, courseNumber);
    
            // Check if the course is already in the arts option
            if (artsOption.completedCourses.some(c => c.course_letter === courseLetter && c.course_number === courseNumber)) {
                console.log("Course already in Arts Option");
                return updatedRequirements;
            }
    
            // Add course to Arts Option
            const newCourse = { course_letter: courseLetter, course_number: courseNumber };
            artsOption.completedCourses.push(newCourse);
    
            // If more than 6 courses are added, start adding them to the "Rest" section
            if (artsOption.completedCourses.length > artsOption.neededCourses) {
                if (!artsOption.rest) {
                    artsOption.rest = [];
                }
                artsOption.rest.push(newCourse);
            }
    
            return updatedRequirements;
        });
    };

    const checkRequirementsSatisfied = () => {
        const { facultyRequirements, majorRequirements, minorRequirements, completedCourses, juniorCore, artsOption } = requirements;
    
        if (!facultyRequirements || !majorRequirements || !minorRequirements || !completedCourses || !juniorCore || !artsOption) {
            return {
                facultyRequirementsSatisfied: false,
                juniorCoreSatisfied: false,
                majorRequirementsSatisfied: false,
                minorRequirementsSatisfied: false,
                artsOptionSatisfied: false
            };
        }
    
        const totalCourses = completedCourses.length;
        const upperLevelCourses = completedCourses.filter(c => c && c.course_number && parseInt(c.course_number) >= 200).length;
    
        const juniorCoreSatisfied = 
            juniorCore.english.length >= 2 &&
            juniorCore.physicalSciences.length >= 2 &&
            juniorCore.mathSciences.length >= 2 &&
            juniorCore.labSciences.length >= 2;
    
        const mandatoryGroups = ['Mandatory Core', 'Mandatory Group 1', 'Mandatory Group 2', 'Mandatory Group 3', 'Mandatory Group 4'];
        const majorRequirementsSatisfied = mandatoryGroups.every(group => {
            const courses = majorRequirements[group];
            const completedCourses = courses.filter(course => course.completed);
            return group === 'Mandatory Group 1' ? completedCourses.length >= 2 : completedCourses.length === courses.length;
        });
    
        const minorRequirementsSatisfied = Object.values(minorRequirements).every(group => 
            group.every(req => req.completed)
        );
    
        const artsOptionSatisfied = requirements.artsOption.completedCourses.length >= requirements.artsOption.neededCourses;
    
        return {
            facultyRequirementsSatisfied: 
                totalCourses >= (facultyRequirements.total_courses_required || 0) &&
                upperLevelCourses >= (facultyRequirements.min_upper_level_courses || 0),
            juniorCoreSatisfied,
            majorRequirementsSatisfied,
            minorRequirementsSatisfied,
            artsOptionSatisfied
        };
    };

    // Rendering functions moved outside of renderRequirements
    const renderJuniorCoreCategory = (category, courses) => (
        <div className="category">
            <h4>{category}</h4>
            <p>{courses.length} / 2 completed</p>
            <div className="course-tags">
                {courses.map((course, index) => (
                    <div key={index} className="course-tag">
                        {course.course_letter} {course.course_number}
                        <button onClick={() => removeCourseFromJuniorCore(category, course)}>×</button>
                    </div>
                ))}
            </div>
        </div>
    );

    const renderMajorRequirements = () => {
        const mandatoryGroups = ['Mandatory Core', 'Mandatory Group 1', 'Mandatory Group 2', 'Mandatory Group 3', 'Mandatory Group 4', 'Rest'];
        
        return mandatoryGroups.map(group => {
            const courses = requirements.majorRequirements[group] || [];
            const completedCourses = courses.filter(course => course.completed);
            let isGroupCompleted = false;
            let requiredCount = courses.length;
    
            if (group === 'Mandatory Group 1') {
                isGroupCompleted = completedCourses.length >= 2;
                requiredCount = 2;
            } else if (group === 'Rest') {
                isGroupCompleted = true; // Always consider 'Rest' as completed
                requiredCount = completedCourses.length; // Show only completed courses count
            } else {
                isGroupCompleted = completedCourses.length === courses.length;
            }
    
            return (
                <div key={group} className="category">
                    <h4>{group} {isGroupCompleted ? '✅' : ''}</h4>
                    {group === 'Rest' ? (
                        <p>{completedCourses.length} courses completed</p>
                    ) : (
                        <p>{completedCourses.length} / {requiredCount} completed</p>
                    )}
                    <div className="course-tags">
                        {completedCourses.map((course, index) => (
                            <div key={index} className="course-tag">
                                {course.course_letter} {course.course_number || course.course_group}
                                <button onClick={() => removeCourseFromMajorRequirements(group, course)}>×</button>
                            </div>
                        ))}
                    </div>
                </div>
            );
        });
    };

    const renderMinorRequirements = () => {
        return Object.entries(requirements.minorRequirements).map(([group, courses]) => {
            const completedCourses = courses.filter(course => course.completed);
            let requiredCount = 1; // Default for Core groups
            let displayedCourses = completedCourses; // By default, show only completed courses

            // Special handling for specific groups
            if (group === 'Mandatory') {
                requiredCount = courses.length;
                displayedCourses = courses; // Show all courses for Mandatory group
            } else if (group.startsWith('Core')) {
                displayedCourses = completedCourses.slice(0, 1); // Show only the first completed course for Core groups
            } else if (group === 'Additional Courses') {
                requiredCount = 55; // Assuming additional courses are required
                displayedCourses = completedCourses;
            } else if (group === 'Mandatory Group 4' || group === 'Mandatory Group 5') {
                requiredCount = 2; // Require 2 courses for Group 4 and Group 5
                displayedCourses = courses; // Show all courses for these groups

                // Conditionally render group only if there are completed courses or progress to show
                if (courses.length === 0) {
                    // Show progress even if no courses are completed
                    displayedCourses = [{ course_letter: "", course_number: "" }];
                }
            }

            // Only render groups with completed courses or necessary progress (e.g., "0/2 completed")
            return (
                <div key={group} className="category">
                    <h4>{group}</h4>
                    <p>{completedCourses.length} / {requiredCount} completed</p>
                    <div className="course-tags">
                        {displayedCourses.map((course, index) => (
                            course.course_letter && course.course_number ? (
                                <div key={index} className="course-tag">
                                    {course.course_letter} {course.course_number}
                                    <button onClick={() => removeCourseFromMinorRequirements(group, course)}>×</button>
                                </div>
                            ) : null
                        ))}
                    </div>
                </div>
            );
        });
    };

    const renderArtsOption = () => {
        const { artsOption } = requirements;
        const requiredArtsCourses = artsOption.completedCourses.slice(0, artsOption.neededCourses);
        const restArtsCourses = artsOption.completedCourses.slice(artsOption.neededCourses);
    
        return (
            <div className="category">
                <h4>{artsOption.title}</h4>
                <p>{requiredArtsCourses.length} / {artsOption.neededCourses} completed</p>
                <div className="course-tags">
                    {requiredArtsCourses.map((course, index) => (
                        <div key={index} className="course-tag">
                            {course.course_letter} {course.course_number}
                            <button onClick={() => removeCourseFromArtsOption(course)}>×</button>
                        </div>
                    ))}
                </div>
    
                {/* Render Rest Section if applicable */}
                {restArtsCourses.length > 0 && (
                    <div className="rest-section">
                        <h4>Additional Arts Courses (Rest)</h4>
                        <p>{restArtsCourses.length} courses added</p>
                        <div className="course-tags">
                            {restArtsCourses.map((course, index) => (
                                <div key={index} className="course-tag">
                                    {course.course_letter} {course.course_number}
                                    <button onClick={() => removeCourseFromArtsOption(course)}>×</button>
                                </div>
                            ))}
                        </div>
                    </div>
                )}
            </div>
        );
    };

    const renderRequirements = () => {
        if (!requirements.facultyRequirements) {
            return <div>Loading requirements...</div>;
        }
    
        const { facultyRequirementsSatisfied, juniorCoreSatisfied, majorRequirementsSatisfied, minorRequirementsSatisfied, artsOptionSatisfied } = checkRequirementsSatisfied();

        return (
            <div className="requirements">
                <h2>Requirements</h2>
    
                <div className="requirement">
                    <h3>Faculty Requirements</h3>
                    <p>Status: {facultyRequirementsSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
                    <p>Total Courses: {requirements.completedCourses.length} / {requirements.facultyRequirements?.total_courses_required}</p>
                    <p>Upper Level Courses: {requirements.completedCourses.filter(c => parseInt(c.course_number) >= 200).length} / {requirements.facultyRequirements?.min_upper_level_courses}</p>
                </div>
    
                <div className="requirement">
                    <h3>Junior Core</h3>
                    <p>Status: {juniorCoreSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
                    <div className="junior-core-categories">
                        {renderJuniorCoreCategory("English", requirements.juniorCore.english)}
                        {renderJuniorCoreCategory("Physical Science", requirements.juniorCore.physicalSciences)}
                        {renderJuniorCoreCategory("Math Science", requirements.juniorCore.mathSciences)}
                        {renderJuniorCoreCategory("Laboratory Science", requirements.juniorCore.labSciences)}
                    </div>
                </div>
    
                <div className="requirement">
                    <h3>Major Requirements ({selectedMajor})</h3>
                    <p>Status: {majorRequirementsSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
                    {renderMajorRequirements()}
                </div>  
    
                <div className="requirement">
                    <h3>Minor Requirements ({selectedMinor})</h3>
                    <p>Status: {minorRequirementsSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
                    {renderMinorRequirements()}
                </div>

                <div className="requirement">
                    <h3>Arts Option</h3>
                    <p>Status: {artsOptionSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
                    {renderArtsOption()}
                </div>
            </div>
        );
    };

    return (
        <div className="simulator">
            <h1>Course Requirement Simulator</h1>
            <div className="program-selection">
                <select value={selectedMajor} onChange={(e) => setSelectedMajor(e.target.value)}>
                    <option value="Computing Science">Computing Science</option>
                    {/* Add more major options here */}
                </select>
                <select value={selectedMinor} onChange={(e) => setSelectedMinor(e.target.value)}>
                    <option value="Mathematics">Mathematics</option>
                    {/* Add more minor options here */}
                </select>
            </div>
            <div className="search-box">
                <input
                    type="text"
                    id="search-input"
                    placeholder="Search for courses..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                {loading && <div className="loading">Loading...</div>}
                {error && <div className="error">{error}</div>}
                {searchResults.length > 0 && (
                    <div id="search-results">
                        {searchResults.map((course) => (
                            <div
                                key={course.course_code}
                                className="course-result"
                                onClick={() => addCourse(course.course_code)}
                            >
                                {course.course_code}: {course.course_name}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div id="requirements">
                {renderRequirements()}
            </div>
        </div>
    );
};

export default Simulator;