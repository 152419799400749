// CoursePilot/src/simulator/faculty_of_science/general/faculty_requirements.js

import React, { useState, useEffect } from 'react';
import './faculty_requirements.css';

const FacultyRequirements = ({ completedCourses }) => {
    const [facultyRequirements, setFacultyRequirements] = useState(null);

    useEffect(() => {
        fetchFacultyRequirements();
    }, []);

    const fetchFacultyRequirements = async () => {
        try {
            const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/science-faculty-requirements');
            if (!response.ok) throw new Error('Failed to fetch faculty requirements');
            const data = await response.json();
            setFacultyRequirements(data);
        } catch (error) {
            console.error('Error fetching faculty requirements:', error);
        }
    };

    const totalCourses = completedCourses.length;
    const upperLevelCourses = completedCourses.filter(c => c && c.course_number && parseInt(c.course_number) >= 200).length;
    const facultyCourses = completedCourses.filter(c => c && c.course.requirements && c.course.requirements.major).length;

    const facultyRequirementsSatisfied = facultyRequirements
        ? totalCourses >= facultyRequirements.totalCoursesRequired &&
          upperLevelCourses >= facultyRequirements.minUpperLevelCourses &&
          facultyCourses >= facultyRequirements.minFacultyCourses
        : false;

    if (!facultyRequirements) {
        return <div>Loading Faculty Requirements...</div>;
    }

    return (
        <div className="requirement">
            <h3>Faculty Requirements</h3>
            <p>Status: {facultyRequirementsSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
            <p>Total Courses: {totalCourses} / {facultyRequirements.totalCoursesRequired}</p>
            <p>Upper Level Courses: {upperLevelCourses} / {facultyRequirements.minUpperLevelCourses}</p>
            <p>Faculty Courses: {facultyCourses} / {facultyRequirements.minFacultyCourses}</p>
        </div>
    );
};

export default FacultyRequirements;