// CoursePilot/src/faculty_of_science/Arts_requirements.js

import React, { useState, useEffect } from 'react';
import './arts_requirements.css';

const ArtsRequirements = ({ completedCourses, removeCourse }) => {
    const [artsOptionCourses, setArtsOptionCourses] = useState([]);
    const neededCourses = 6;

    useEffect(() => {
        updateArtsOptionCourses();
    }, [completedCourses]);

    const updateArtsOptionCourses = () => {
        const artsCourses = completedCourses.filter(course => {
            // Assume the course object contains a property indicating if it's an arts option course
            return course.course && course.course.requirements && course.course.requirements.artsOption;
        });
        setArtsOptionCourses(artsCourses);
    };

    const removeCourseFromArtsOption = (courseToRemove) => {
        removeCourse(courseToRemove.course_letter, courseToRemove.course_number);
    };

    const requiredArtsCourses = artsOptionCourses.slice(0, neededCourses);
    const restArtsCourses = artsOptionCourses.slice(neededCourses);

    const artsOptionSatisfied = requiredArtsCourses.length >= neededCourses;

    return (
        <div className="requirement">
            <h3>Arts Option</h3>
            <p>Status: {artsOptionSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
            <p>{requiredArtsCourses.length} / {neededCourses} completed</p>
            <div className="course-tags">
                {requiredArtsCourses.map((course, index) => (
                    <div key={index} className="course-tag">
                        {course.course_letter} {course.course_number}
                        <button onClick={() => removeCourseFromArtsOption(course)}>×</button>
                    </div>
                ))}
            </div>

            {restArtsCourses.length > 0 && (
                <div className="rest-section">
                    <h4>Additional Arts Courses (Rest)</h4>
                    <p>{restArtsCourses.length} courses added</p>
                    <div className="course-tags">
                        {restArtsCourses.map((course, index) => (
                            <div key={index} className="course-tag">
                                {course.course_letter} {course.course_number}
                                <button onClick={() => removeCourseFromArtsOption(course)}>×</button>
                            </div>
                        ))}
                    </div>
                </div>
            )}
        </div>
    );
};

export default ArtsRequirements;