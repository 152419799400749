import React, { useState, useEffect, Suspense } from 'react';
import JuniorCore from './simulator/faculty_of_science/general/junior_requirements.js';
import ArtsRequirements from './simulator/faculty_of_science/general/arts_requirements.js';
import FacultyRequirements from './simulator/faculty_of_science/general/faculty_requirements.js';
import './CourseSimulatorDetails.css';

const CourseSimulatorDetails = ({ MajorComponent, MinorComponent, selectedMajor, selectedMinor }) => {
    const [searchQuery, setSearchQuery] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [allCourses, setAllCourses] = useState([]);
    const [completedCourses, setCompletedCourses] = useState([]);

    useEffect(() => {
        fetchAllCourses();
    }, []);

    const fetchAllCourses = async () => {
        try {
            const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/coursereq/courses');
            if (!response.ok) throw new Error('Failed to fetch all courses');
            const data = await response.json();
            setAllCourses(data);
        } catch (error) {
            console.error('Error fetching all courses:', error);
            setError('Failed to fetch courses. Please try again.');
        }
    };

    useEffect(() => {
        const fetchCourses = async () => {
            if (!searchQuery) {
                setSearchResults([]);
                return;
            }

            setLoading(true);
            setError(null);

            try {
                const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/coursereq/search?query=${encodeURIComponent(searchQuery)}`);
                if (!response.ok) throw new Error('Network response was not ok');
                const data = await response.json();
                setSearchResults(data.courses);
            } catch (error) {
                console.error('Error fetching courses:', error);
                setError('Failed to fetch courses. Please try again.');
                setSearchResults([]);
            } finally {
                setLoading(false);
            }
        };

        const debounceTimer = setTimeout(() => {
            fetchCourses();
        }, 300);

        return () => clearTimeout(debounceTimer);
    }, [searchQuery]);

    const addCourse = (courseCode) => {
        const splitIndex = courseCode.lastIndexOf(' ');
        const courseLetter = courseCode.slice(0, splitIndex).trim();
        const courseNumber = courseCode.slice(splitIndex + 1).trim();

        const course = allCourses.find(c => 
            c.course.course_letter === courseLetter && 
            c.course.course_number === courseNumber
        );

        if (!course) return;

        if (completedCourses.some(c => c.course_letter === courseLetter && c.course_number === courseNumber)) {
            return;
        }

        setCompletedCourses(prev => [...prev, { course_letter: courseLetter, course_number: courseNumber, course }]);

        setSearchQuery('');
        setSearchResults([]);
    };

    const removeCourse = (courseLetter, courseNumber) => {
        setCompletedCourses(prev => prev.filter(c => c.course_letter !== courseLetter || c.course_number !== courseNumber));
    };

    const renderMajorRequirements = () => {
        if (MajorComponent) {
            return (
                <Suspense fallback={<div className="loading">Loading Major...</div>}>
                    <MajorComponent
                        completedCourses={completedCourses}
                        removeCourse={removeCourse}
                    />
                </Suspense>
            );
        }
        return null;
    };

    const renderMinorRequirements = () => {
        if (MinorComponent) {
            return (
                <Suspense fallback={<div className="loading">Loading Minor...</div>}>
                    <MinorComponent
                        key={`minor-${selectedMinor}`}
                        completedCourses={completedCourses}
                        removeCourse={removeCourse}
                    />
                </Suspense>
            );
        }
        return null;
    };

    return (
        <div className="course-simulator-details">
            <div className="search-box">
                <input
                    type="text"
                    id="search-input"
                    placeholder="Search for courses..."
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                />
                {loading && <div className="android-spinner"></div>}
                {error && <div className="error">{error}</div>}
                {searchResults.length > 0 && (
                    <div id="search-results">
                        {searchResults.map((course) => (
                            <div
                                key={course.course_code}
                                className="course-result"
                                onClick={() => addCourse(course.course_code)}
                            >
                                {course.course_code}: {course.course_name}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div id="requirements">
                <h2>Requirements</h2>
                <FacultyRequirements
                    completedCourses={completedCourses}
                />
                <JuniorCore
                    completedCourses={completedCourses}
                    removeCourse={removeCourse}
                />
                {renderMajorRequirements()}
                {renderMinorRequirements()}
                <ArtsRequirements
                    completedCourses={completedCourses}
                    removeCourse={removeCourse}
                />
            </div>
        </div>
    );
};

export default CourseSimulatorDetails;