import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './CoursePage.css';

const CoursePage = () => {
  const { courseCode } = useParams();
  const [lectures, setLectures] = useState([]);
  const [labs, setLabs] = useState([]);
  const [gpas, setGpas] = useState([]);
  const [loadingLectures, setLoadingLectures] = useState(true);
  const [loadingLabs, setLoadingLabs] = useState(false);
  const [loadingGpas, setLoadingGpas] = useState(false);
  const [error, setError] = useState(null);
  const [showLectures, setShowLectures] = useState(true);
  const [showLabs, setShowLabs] = useState(false);
  const [showGpas, setShowGpas] = useState(false);
  const [expandedLabIndex, setExpandedLabIndex] = useState(null);
  const [courseTitle, setCourseTitle] = useState('');
  const [averageGpa, setAverageGpa] = useState('N/A');
  const [courseUnits, setCourseUnits] = useState('');
  const [courseDescription, setCourseDescription] = useState('');
  const [prerequisiteDescription, setPrerequisiteDescription] = useState('');
  const [corequisiteDescription, setCorequisiteDescription] = useState('');
  const [currentQuestionIndices, setCurrentQuestionIndices] = useState({});
  const [expandedLectureIndices, setExpandedLectureIndices] = useState([]);
  const [touchStartX, setTouchStartX] = useState(null);
  const [touchStartY, setTouchStartY] = useState(null);
  const [isScrolling, setIsScrolling] = useState(false);
  const [isMobileView, setIsMobileView] = useState(window.innerWidth <= 600);


  
  useEffect(() => {
    fetchLectureData();
    fetchAverageGpaData();
  }, [courseCode]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 600);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formatName = (name) => {
    return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
  };

  const fetchLectureData = async () => {
    setLoadingLectures(true);
    try {
      const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/course/${courseCode}`);
      if (!response.ok) {
        throw new Error('Failed to fetch course details');
      }
      const data = await response.json();
      setLectures(data.sections || []);
      setCourseTitle(data.courseTitle || '');
      setCourseUnits(data.units?.toString().charAt(0) || '');

      const description = data.courseDescription || '';
      let prerequisiteDescription = '';
      let corequisiteDescription = '';
      let mainDescription = description;

      const prerequisiteRegex = /Prerequisites?:/i;
      const corequisiteRegex = /Corequisites?:/i;

      const prerequisiteMatch = description.match(prerequisiteRegex);
      if (prerequisiteMatch) {
        const prerequisiteIndex = prerequisiteMatch.index;
        mainDescription = description.substring(0, prerequisiteIndex).trim();

        const corequisiteMatch = description.match(corequisiteRegex);
        if (corequisiteMatch) {
          const corequisiteIndex = corequisiteMatch.index;
          prerequisiteDescription = description.substring(
            prerequisiteIndex + prerequisiteMatch[0].length,
            corequisiteIndex
          ).trim();
          corequisiteDescription = description.substring(corequisiteIndex + corequisiteMatch[0].length).trim();
        } else {
          prerequisiteDescription = description.substring(prerequisiteIndex + prerequisiteMatch[0].length).trim();
        }
      } else {
        mainDescription = description;
      }

      setCourseDescription(mainDescription);
      setPrerequisiteDescription(prerequisiteDescription);
      setCorequisiteDescription(corequisiteDescription);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching lecture data:', error);
    } finally {
      setLoadingLectures(false);
    }
  };

  const fetchLabData = async () => {
    setLoadingLabs(true);
    try {
      const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/course/${courseCode}/labs`);
      if (!response.ok) {
        throw new Error('Failed to fetch lab details');
      }
      const data = await response.json();
      setLabs(data.labs || []);
    } catch (error) {
      setError(error.message);
      console.error('Error fetching lab data:', error);
    } finally {
      setLoadingLabs(false);
    }
  };

  const fetchAverageGpaData = async () => {
    try {
      console.log('Fetching average GPA data for course:', courseCode);
      const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/course/${courseCode}/gpas`);

      if (!response.ok) {
        console.error('Failed to fetch average GPA details:', response.statusText);
        throw new Error('Failed to fetch average GPA details');
      }

      const data = await response.json();
      console.log('Fetched Average GPA Data:', data);

      if (data.length > 0) {
        const totalGpa = data.reduce((sum, item) => sum + parseFloat(item.gpa), 0);
        const avgGpa = (totalGpa / data.length).toFixed(2);
        setAverageGpa(avgGpa);
      } else {
        setAverageGpa('N/A');
      }
    } catch (error) {
      console.error('Error fetching average GPA data:', error);
      setAverageGpa('N/A');
    }
  };

  const fetchGpaData = async () => {
    setLoadingGpas(true);
    try {
      console.log('Fetching GPA data for course:', courseCode);
      const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/course/${courseCode}/gpas`);

      if (response.status === 404) {
        console.warn('No GPA data available for this course.');
        setGpas([]);
        setAverageGpa('N/A');
        return;
      }

      if (!response.ok) {
        console.error('Failed to fetch GPA details:', response.statusText);
        throw new Error('Failed to fetch GPA details');
      }

      const data = await response.json();
      console.log('Fetched GPA Data:', data);
      setGpas(data || []);

      if (data.length > 0) {
        const totalGpa = data.reduce((acc, gpa) => acc + parseFloat(gpa.gpa), 0);
        const average = (totalGpa / data.length).toFixed(2);
        setAverageGpa(average);
      } else {
        setAverageGpa('N/A');
      }
    } catch (error) {
      setError(error.message);
      console.error('Error fetching GPA data:', error);
    } finally {
      setLoadingGpas(false);
    }
  };

  const toggleLectureExpansion = (index) => {
    setExpandedLectureIndices((prevIndices) => {
      if (prevIndices.includes(index)) {
        const element = document.getElementById(`lecture-details-${index}`);
        if (element) {
          element.style.height = element.scrollHeight + 'px';
          requestAnimationFrame(() => {
            element.style.height = '0';
          });
        }
        return prevIndices.filter((i) => i !== index);
      } else {
        const newIndices = [...prevIndices, index];
        setTimeout(() => {
          const element = document.getElementById(`lecture-details-${index}`);
          if (element) {
            element.style.height = 'auto';
            const scrollHeight = element.scrollHeight;
            element.style.height = '0';
            requestAnimationFrame(() => {
              element.style.height = scrollHeight + 'px';
            });
            element.addEventListener(
              'transitionend',
              function () {
                if (newIndices.includes(index)) {
                  element.style.height = 'auto';
                }
              },
              { once: true }
            );
          }
        }, 0);
        return newIndices;
      }
    });
  };

  const toggleLabExpansion = (index) => {
    if (expandedLabIndex === index) {
      const element = document.getElementById(`lab-details-${index}`);
      if (element) {
        element.style.height = element.scrollHeight + 'px';
        requestAnimationFrame(() => {
          element.style.height = '0';
        });
      }
      setExpandedLabIndex(null);
    } else {
      const prevIndex = expandedLabIndex;
      setExpandedLabIndex(index);
      setTimeout(() => {
        const element = document.getElementById(`lab-details-${index}`);
        if (element) {
          element.style.height = 'auto';
          const scrollHeight = element.scrollHeight;
          element.style.height = '0';
          requestAnimationFrame(() => {
            element.style.height = scrollHeight + 'px';
          });
          element.addEventListener(
            'transitionend',
            function () {
              if (expandedLabIndex === index) {
                element.style.height = 'auto';
              }
            },
            { once: true }
          );
        }
      }, 0);
      if (prevIndex !== null && prevIndex !== index) {
        const prevElement = document.getElementById(`lab-details-${prevIndex}`);
        if (prevElement) {
          prevElement.style.height = prevElement.scrollHeight + 'px';
          requestAnimationFrame(() => {
            prevElement.style.height = '0';
          });
        }
      }
    }
    setCurrentQuestionIndices((prev) => ({ ...prev, [`lab-${index}`]: 0 }));
  };

  const calculateAverageMedianRating = (questions) => {
    if (!questions || questions.length === 0) return 'N/A';

    const totalResponses = questions.reduce((acc, question) => {
      return (
        acc +
        question.strongly_disagree +
        question.disagree +
        question.neither +
        question.agree +
        question.strongly_agree
      );
    }, 0);

    const weightedSum = questions.reduce((acc, question) => {
      return (
        acc +
        question.strongly_disagree * 0 +
        question.disagree * 25 +
        question.neither * 50 +
        question.agree * 75 +
        question.strongly_agree * 100
      );
    }, 0);

    const averagePercentage = (weightedSum / totalResponses).toFixed(2);
    return `${averagePercentage}%`;
  };

  const handleTouchStart = (e) => {
    setTouchStartX(e.touches[0].clientX);
    setTouchStartY(e.touches[0].clientY);
    setIsScrolling(false);
  };

  const handleTouchMove = (e) => {
    if (!touchStartX || !touchStartY) return;

    const touchX = e.touches[0].clientX;
    const touchY = e.touches[0].clientY;
    const deltaX = touchStartX - touchX;
    const deltaY = touchStartY - touchY;

    if (Math.abs(deltaX) > Math.abs(deltaY) && Math.abs(deltaX) > 10) {
      setIsScrolling(true);
    }
  };

  const handleTouchEnd = (e, index) => {
    if (isScrolling) {
      setIsScrolling(false);
      setTouchStartX(null);
      setTouchStartY(null);
      return;
    }

    const target = e.target;
    if (target.tagName.toLowerCase() === 'button') {
      toggleLectureExpansion(index);
    }

    setTouchStartX(null);
    setTouchStartY(null);
  };

  const renderFeedbackBar = (question) => {
    const total =
      question.strongly_disagree +
      question.disagree +
      question.neither +
      question.agree +
      question.strongly_agree;
    const percentages = {
      strongly_disagree: (question.strongly_disagree / total) * 100,
      disagree: (question.disagree / total) * 100,
      neither: (question.neither / total) * 100,
      agree: (question.agree / total) * 100,
      strongly_agree: (question.strongly_agree / total) * 100,
    };

    return (
      <div className="feedback-bar">
        <div className="bar-segment strongly-disagree" style={{ width: `${percentages.strongly_disagree}%` }}></div>
        <div className="bar-segment disagree" style={{ width: `${percentages.disagree}%` }}></div>
        <div className="bar-segment neither" style={{ width: `${percentages.neither}%` }}></div>
        <div className="bar-segment agree" style={{ width: `${percentages.agree}%` }}></div>
        <div className="bar-segment strongly-agree" style={{ width: `${percentages.strongly_agree}%` }}></div>
      </div>
    );
  };

  const renderFeedbackSlider = (questions, offeringId, currentQuestionIndices, setCurrentQuestionIndices) => {
    if (!questions || questions.length === 0) {
      return <p>No feedback available.</p>;
    }

    const currentIndex = currentQuestionIndices[offeringId] || 0;
    const currentQuestion = questions[currentIndex];

    const handlePrevQuestion = () => {
      const feedbackContent = document.querySelector(`#feedback-content-${offeringId}`);
      feedbackContent.classList.remove('slide-right-in', 'slide-left-in');
      feedbackContent.classList.add('slide-right-out');
      setTimeout(() => {
        setCurrentQuestionIndices((prev) => ({
          ...prev,
          [offeringId]: prev[offeringId] > 0 ? prev[offeringId] - 1 : questions.length - 1,
        }));
        feedbackContent.classList.remove('slide-right-out');
        feedbackContent.classList.add('slide-left-in');
      }, 300);
    };

    const handleNextQuestion = () => {
      const feedbackContent = document.querySelector(`#feedback-content-${offeringId}`);
      feedbackContent.classList.remove('slide-right-in', 'slide-left-in');
      feedbackContent.classList.add('slide-left-out');
      setTimeout(() => {
        setCurrentQuestionIndices((prev) => ({
          ...prev,
          [offeringId]: prev[offeringId] < questions.length - 1 ? prev[offeringId] + 1 : 0,
        }));
        feedbackContent.classList.remove('slide-left-out');
        feedbackContent.classList.add('slide-right-in');
      }, 300);
    };

    return (
      <div className="feedback-mini-card">
        <div className="feedback-slider">
          <div className="feedback-size">Feedback</div>
          <div id={`feedback-content-${offeringId}`} className="feedback-content">
            <div className="feedback-question">{currentQuestion.question_text}</div>
            {renderFeedbackBar(currentQuestion)}
            <div className="feedback-stats">
              <p style={{ color: '#FF4136' }}>Strongly Disagree: {currentQuestion.strongly_disagree}</p>
              <p style={{ color: '#FF851B' }}>Disagree: {currentQuestion.disagree}</p>
              <p style={{ color: '#FFDC00' }}>Neither: {currentQuestion.neither}</p>
              <p style={{ color: '#2ECC40' }}>Agree: {currentQuestion.agree}</p>
              <p style={{ color: '#0074D9' }}>Strongly Agree: {currentQuestion.strongly_agree}</p>
              <p>Median: {currentQuestion.median}</p>
            </div>
          </div>
          <div className="slider-controls">
            <button onClick={handlePrevQuestion} disabled={questions.length <= 1}>
              &lt;
            </button>
            <span>
              {currentIndex + 1} / {questions.length}
            </span>
            <button onClick={handleNextQuestion} disabled={questions.length <= 1}>
              &gt;
            </button>
          </div>
        </div>
      </div>
    );
  };

  if (loadingLectures) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading course details...</p>
      </div>
    );
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  if (lectures.length === 0) {
    return <p>No lecture data available for this course.</p>;
  }

  return (
    <div className="course-page">
      <header className="course-info">
        <h1 className="course-code">{lectures[0]?.course_code || courseCode}</h1>
        <h2 className="course-title">{courseTitle || lectures[0]?.course_name || 'Course Name Unavailable'}</h2>
        <p className="course-units">
          <strong>Credit hours:</strong> {courseUnits || 'N/A'} credit(s).
        </p>
        {averageGpa !== 'N/A' ? (
          <p className="average-gpa">
            <strong>Average GPA: </strong>
            <span style={{ color: '#ffec80', fontWeight: 'bold', fontSize: '1.2rem' }}>{averageGpa}</span>, across all
            sections.
          </p>
        ) : (
          <p className="average-gpa">
            <strong>No GPA data available.</strong>
          </p>
        )}
        <p className="course-description">
          <strong>Description:</strong> {courseDescription || 'N/A'}
        </p>
        {prerequisiteDescription && (
          <p className="prerequisite">
            <strong>Prerequisite:</strong> {prerequisiteDescription}
          </p>
        )}
        {corequisiteDescription && (
          <p className="corequisite">
            <strong>Corequisite:</strong> {corequisiteDescription}
          </p>
        )}
      </header>

      <div className="button-container">
        <button
          className={`toggle-button ${showLectures ? 'active' : ''}`}
          onClick={() => {
            setShowLectures(true);
            setShowLabs(false);
            setShowGpas(false);
          }}
        >
          Show Lectures
        </button>
        <button
          className={`toggle-button ${showLabs ? 'active' : ''}`}
          onClick={() => {
            setShowLectures(false);
            setShowLabs(true);
            setShowGpas(false);
            if (labs.length === 0 && !loadingLabs) {
              fetchLabData();
            }
          }}
        >
          Show Labs
        </button>
        <button
          className={`toggle-button ${showGpas ? 'active' : ''}`}
          onClick={() => {
            setShowLectures(false);
            setShowLabs(false);
            setShowGpas(true);
            if (gpas.length === 0 && !loadingGpas) {
              fetchGpaData();
            }
          }}
        >
          Show GPAs
        </button>
      </div>

      <main>
        {showLectures ? (
          isMobileView ? (
            <section className="lectures-section">
              <h2>Lectures</h2>
              {lectures.map((offering, index) => (
                <div className="lecture-card" key={index}>
                  <div className="lecture-card-header">
                    <h3>Section {offering.section}</h3>
                    <button className="details-button" onClick={() => toggleLectureExpansion(index)}>
                      {expandedLectureIndices.includes(index) ? 'Hide' : 'Show'} Details
                    </button>
                  </div>
                  <p>
                    <strong>Instructor:</strong>
                    <Link to={`/professor/${offering.first_name}-${offering.last_name}`}>
                      {` ${formatName(offering.first_name)} ${formatName(offering.last_name)}`}
                    </Link>
                  </p>
                  <p>
                    <strong>Academic Year:</strong> {offering.academic_year}
                  </p>
                  <p>
                    <strong>Class Size:</strong> {offering.class_size}
                  </p>
                  <p>
                    <strong>Response Count:</strong> {offering.response_count}
                  </p>
                  <p>
                    <strong>Average Median Rating:</strong> {calculateAverageMedianRating(offering.questions)}
                  </p>
                  {expandedLectureIndices.includes(index) && (
                    <div
                      id={`lecture-details-${index}`}
                      className="details-container expanded"
                      style={{ height: expandedLectureIndices.includes(index) ? 'auto' : '0' }}
                      onTransitionEnd={(e) => {
                        if (expandedLectureIndices.includes(index) && e.propertyName === 'height') {
                          e.target.style.height = 'auto';
                        }
                      }}
                    >
                      {renderFeedbackSlider(
                        offering.questions,
                        `lecture-${index}`,
                        currentQuestionIndices,
                        setCurrentQuestionIndices
                      )}
                    </div>
                  )}
                </div>
              ))}
            </section>
          ) : (
            <section className="lectures-section">
              <h2>Lectures</h2>
              <div className="table-container">
                <table className="data-table">
                  <thead>
                    <tr>
                      <th>Section</th>
                      <th>Instructor</th>
                      <th>Academic Year</th>
                      <th>Class Size</th>
                      <th>Response Count</th>
                      <th>Average Median Rating</th>
                      <th>Details</th>
                    </tr>
                  </thead>
                  <tbody>
                    {lectures.map((offering, index) => (
                      <React.Fragment key={index}>
                        <tr
                          className="table-row"
                          onTouchStart={handleTouchStart}
                          onTouchMove={handleTouchMove}
                          onTouchEnd={(e) => handleTouchEnd(e, index)}
                        >
                          <td>{offering.section}</td>
                          <td>
                            <Link to={`/professor/${offering.first_name}-${offering.last_name}`}>
                              {`${formatName(offering.first_name)} ${formatName(offering.last_name)}`}
                            </Link>
                          </td>
                          <td>{offering.academic_year}</td>
                          <td>{offering.class_size}</td>
                          <td>{offering.response_count}</td>
                          <td>{calculateAverageMedianRating(offering.questions)}</td>
                          <td>
                            <button className="details-button" onClick={() => toggleLectureExpansion(index)}>
                              {expandedLectureIndices.includes(index) ? 'Hide' : 'Show'} Details
                            </button>
                          </td>
                        </tr>
                        {expandedLectureIndices.includes(index) && (
                          <tr className="details-row">
                            <td colSpan="7">
                              <div
                                id={`lecture-details-${index}`}
                                className="details-container expanded"
                                style={{ height: expandedLectureIndices.includes(index) ? 'auto' : '0' }}
                                onTransitionEnd={(e) => {
                                  if (expandedLectureIndices.includes(index) && e.propertyName === 'height') {
                                    e.target.style.height = 'auto';
                                  }
                                }}
                              >
                                {renderFeedbackSlider(
                                  offering.questions,
                                  `lecture-${index}`,
                                  currentQuestionIndices,
                                  setCurrentQuestionIndices
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            </section>
          )
        ) : showLabs ? (
          isMobileView ? (
            <section className="labs-section">
              <h2>Labs</h2>
              {loadingLabs ? (
                <div className="loading-screen">
                  <div className="spinner"></div>
                  <p>Loading lab details...</p>
                </div>
              ) : labs.length > 0 ? (
                labs.map((lab, index) => (
                  <div className="lab-card" key={index}>
                    <div className="lab-card-header">
                      <h3>Section {lab.section}</h3>
                      <button className="details-button" onClick={() => toggleLabExpansion(index)}>
                        {expandedLabIndex === index ? 'Hide' : 'Show'} Details
                      </button>
                    </div>
                    <p>
                      <strong>Instructor:</strong>
                      <Link to={`/professor/${lab.first_name}-${lab.last_name}`}>
                        {` ${formatName(lab.first_name)} ${formatName(lab.last_name)}`}
                      </Link>
                    </p>
                    <p>
                      <strong>Academic Year:</strong> {lab.academic_year}
                    </p>
                    <p>
                      <strong>Lab Size:</strong> {lab.class_size}
                    </p>
                    <p>
                      <strong>Response Count:</strong> {lab.response_count}
                    </p>
                    <p>
                      <strong>Average Median Rating:</strong> {calculateAverageMedianRating(lab.questions)}
                    </p>
                    {expandedLabIndex === index && (
                      <div
                        id={`lab-details-${index}`}
                        className="details-container expanded"
                        style={{ height: expandedLabIndex === index ? 'auto' : '0' }}
                        onTransitionEnd={(e) => {
                          if (expandedLabIndex === index && e.propertyName === 'height') {
                            e.target.style.height = 'auto';
                          }
                        }}
                      >
                        {renderFeedbackSlider(
                          lab.questions,
                          `lab-${index}`,
                          currentQuestionIndices,
                          setCurrentQuestionIndices
                        )}
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>No lab data available.</p>
              )}
            </section>
          ) : (
            <section className="labs-section">
              <h2>Labs</h2>
              {loadingLabs ? (
                <div className="loading-screen">
                  <div className="spinner"></div>
                  <p>Loading lab details...</p>
                </div>
              ) : labs.length > 0 ? (
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Section</th>
                        <th>Instructor</th>
                        <th>Academic Year</th>
                        <th>Lab Size</th>
                        <th>Response Count</th>
                        <th>Average Median Rating</th>
                        <th>Details</th>
                      </tr>
                    </thead>
                    <tbody>
                      {labs.map((lab, index) => (
                        <React.Fragment key={index}>
                          <tr>
                            <td>{lab.section}</td>
                            <td>
                              <Link to={`/professor/${lab.first_name}-${lab.last_name}`}>
                                {`${formatName(lab.first_name)} ${formatName(lab.last_name)}`}
                              </Link>
                            </td>
                            <td>{lab.academic_year}</td>
                            <td>{lab.class_size}</td>
                            <td>{lab.response_count}</td>
                            <td>{calculateAverageMedianRating(lab.questions)}</td>
                            <td>
                              <button className="details-button" onClick={() => toggleLabExpansion(index)}>
                                {expandedLabIndex === index ? 'Hide' : 'Show'} Details
                              </button>
                            </td>
                          </tr>
                          {expandedLabIndex === index && (
                            <tr className="details-row">
                              <td colSpan="7">
                                <div
                                  id={`lab-details-${index}`}
                                  className="details-container expanded"
                                  style={{ height: expandedLabIndex === index ? 'auto' : '0' }}
                                  onTransitionEnd={(e) => {
                                    if (expandedLabIndex === index && e.propertyName === 'height') {
                                      e.target.style.height = 'auto';
                                    }
                                  }}
                                >
                                  {renderFeedbackSlider(
                                    lab.questions,
                                    `lab-${index}`,
                                    currentQuestionIndices,
                                    setCurrentQuestionIndices
                                  )}
                                </div>
                              </td>
                            </tr>
                          )}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No lab data available.</p>
              )}
            </section>
          )
        ) : showGpas ? (
          isMobileView ? (
            <section className="gpas-section">
              <h2>GPAs</h2>
              {loadingGpas ? (
                <div className="loading-screen">
                  <div className="spinner"></div>
                  <p>Loading GPA details...</p>
                </div>
              ) : gpas.length > 0 ? (
                gpas.map((gpa, index) => (
                  <div className="gpa-card" key={index}>
                    <p>
                      <strong>Professor Names:</strong> {gpa.professornames}
                    </p>
                    <p>
                      <strong>Term:</strong> {gpa.term}
                    </p>
                    <p>
                      <strong>Section:</strong> {gpa.section}
                    </p>
                    <p>
                      <strong>GPA:</strong> {gpa.gpa}
                    </p>
                  </div>
                ))
              ) : (
                <p>No GPA data available.</p>
              )}
            </section>
          ) : (
            <section className="gpas-section">
              <h2>GPAs</h2>
              {loadingGpas ? (
                <div className="loading-screen">
                  <div className="spinner"></div>
                  <p>Loading GPA details...</p>
                </div>
              ) : gpas.length > 0 ? (
                <div className="table-container">
                  <table className="data-table">
                    <thead>
                      <tr>
                        <th>Professor Names</th>
                        <th>Term</th>
                        <th>Section</th>
                        <th>GPA</th>
                      </tr>
                    </thead>
                    <tbody>
                      {gpas.map((gpa, index) => (
                        <tr key={index}>
                          <td>{gpa.professornames}</td>
                          <td>{gpa.term}</td>
                          <td>{gpa.section}</td>
                          <td>{gpa.gpa}</td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <p>No GPA data available.</p>
              )}
            </section>
          )
        ) : null}
      </main>

      <footer>
        <p>&copy; 2024 BearPath. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default CoursePage;