import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import SearchBar from './SearchBar';
import IndexPage from './IndexPage';
import DataUpload from './DataUpload';
import CoursePage from './CoursePage';
import ProfessorPage from './ProfessorPage';
import HomePage from './HomePage';
import PopularPage from './PopularPage';
import Simulator from './Simulator';
import CourseSimulator from './CourseSimulator';
import Helper from './Helper';
import RandomPage from './RandomPage';
import FAQpage from "./FAQpage";

function App() {
  return (
    <Router>
      <div className="app">
        <SearchBar />
        <main className="main-content">
          <Routes>
            <Route path="/" element={<HomePage />} />
            <Route path="/indexpage" element={<IndexPage />} />
            <Route path="/popular" element={<PopularPage />} />
            <Route path="/dataupload" element={<DataUpload />} />
            <Route path="/course/:courseCode" element={<CoursePage  />} />
            <Route path="/professor/:professorSlug" element={<ProfessorPage />} /> 
            <Route path="/Simulator" element={<Simulator />} /> 
            <Route path="/SIM" element={<CourseSimulator />} /> 
            <Route path="/AI" element={<Helper />} /> 
            <Route path="/random" element={<RandomPage />} />
            <Route path="/FAQ" element={<FAQpage />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;