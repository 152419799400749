import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './RandomPage.css';

const RandomPage = () => {
  const [randomItem, setRandomItem] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const fetchRandomItem = async () => {
    setLoading(true);
    try {
      const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/random');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const data = await response.json();
      setRandomItem(data);
      setError(null);

      // Auto-navigate after 2 seconds
      setTimeout(() => {
        if (data.type === 'course') {
          navigate(`/course/${data.course_code}`);
        } else {
          const professorName = `${data.first_name.toUpperCase()}-${data.last_name.toUpperCase()}`;
          navigate(`/professor/${professorName}`);
        }
      }, 2000);

    } catch (error) {
      console.error('Error fetching random item:', error);
      setError('Failed to fetch random item. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchRandomItem();
  }, []);

  const handleTryAgain = () => {
    fetchRandomItem();
  };

  const formatName = (name) => {
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  };

  return (
    <div className="random-page">
      <div className="random-container">
        <h1>🎲 Random Selection</h1>
        
        {loading ? (
          <div className="loading-animation">
            <div className="dice-animation">🎲</div>
            <p>Rolling the dice...</p>
          </div>
        ) : error ? (
          <div className="error-container">
            <p className="error-message">{error}</p>
            <button onClick={handleTryAgain} className="try-again-button">
              Try Again
            </button>
          </div>
        ) : randomItem && (
          <div className="result-container">
            <div className="result-type">
              {randomItem.type === 'course' ? '📚 Random Course' : '🧑‍🏫 Random Instructor'}
            </div>
            <div className="result-content">
              {randomItem.type === 'course' ? (
                <>
                  <h2>{randomItem.course_code}</h2>
                  <p>{randomItem.course_name}</p>
                </>
              ) : (
                <>
                  <h2>{`${formatName(randomItem.first_name)} ${formatName(randomItem.last_name)}`}</h2>
                  <p>{`${randomItem.department_name}, ${randomItem.faculty}`}</p>
                </>
              )}
            </div>
            <p className="redirect-message">Redirecting you in a moment...</p>
            <button onClick={handleTryAgain} className="try-again-button">
              Roll Again 🎲
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default RandomPage;