// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.data-upload-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .data-upload-container h2 {
    color: #333;
    margin-bottom: 20px;
  }
  
  .data-upload-container form {
    display: flex;
    flex-direction: column;
  }
  
  .data-upload-container input,
  .data-upload-container textarea {
    margin-bottom: 10px;
    padding: 8px;
    border: 1px solid #ddd;
    border-radius: 4px;
  }
  
  .data-upload-container button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .data-upload-container button:hover {
    background-color: #45a049;
  }
  
  .question-container {
    border: 1px solid #ddd;
    padding: 10px;
    margin-bottom: 10px;
    border-radius: 4px;
  }
  
  .message {
    margin-top: 20px;
    padding: 10px;
    border-radius: 4px;
    background-color: #f2f2f2;
  }`, "",{"version":3,"sources":["webpack://./src/DataUpload.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,cAAc;IACd,aAAa;IACb,8BAA8B;EAChC;;EAEA;IACE,WAAW;IACX,mBAAmB;EACrB;;EAEA;IACE,aAAa;IACb,sBAAsB;EACxB;;EAEA;;IAEE,mBAAmB;IACnB,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;EAClB;;EAEA;IACE,yBAAyB;EAC3B;;EAEA;IACE,sBAAsB;IACtB,aAAa;IACb,mBAAmB;IACnB,kBAAkB;EACpB;;EAEA;IACE,gBAAgB;IAChB,aAAa;IACb,kBAAkB;IAClB,yBAAyB;EAC3B","sourcesContent":[".data-upload-container {\n    max-width: 800px;\n    margin: 0 auto;\n    padding: 20px;\n    font-family: Arial, sans-serif;\n  }\n  \n  .data-upload-container h2 {\n    color: #333;\n    margin-bottom: 20px;\n  }\n  \n  .data-upload-container form {\n    display: flex;\n    flex-direction: column;\n  }\n  \n  .data-upload-container input,\n  .data-upload-container textarea {\n    margin-bottom: 10px;\n    padding: 8px;\n    border: 1px solid #ddd;\n    border-radius: 4px;\n  }\n  \n  .data-upload-container button {\n    background-color: #4CAF50;\n    color: white;\n    padding: 10px 15px;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    margin-top: 10px;\n  }\n  \n  .data-upload-container button:hover {\n    background-color: #45a049;\n  }\n  \n  .question-container {\n    border: 1px solid #ddd;\n    padding: 10px;\n    margin-bottom: 10px;\n    border-radius: 4px;\n  }\n  \n  .message {\n    margin-top: 20px;\n    padding: 10px;\n    border-radius: 4px;\n    background-color: #f2f2f2;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
