// CoursePilot/src/simulator/programConfig.js

export const majorConfig = {
    'Computing Science': {
        component: () => import('./general/major_requirements/computing_science.js'),
        name: 'Computing Science',
    },
    'Chemistry': {
        component: () => import('./general/major_requirements/chemistry.js'),
        name: 'Chemistry',
    },
    'Biological Science': {
        component: () => import('./general/major_requirements/bio_science.js'),
        name: 'Biological Science',
    },
    'Mathematics': {
        component: () => import('./general/major_requirements/mathematics.js'),
        name: 'Mathematics',
    },
    // Add more majors as needed
};

export const minorConfig = {
    'Mathematics': {
        component: () => import('./general/minor_requirements/mathematics.js'),
        name: 'Mathematics',
    },
    'Computing Science': {
        component: () => import('./general/minor_requirements/computing_science.js'),
        name: 'Computing Science',
    },
    'Physics': {
        component: () => import('./general/minor_requirements/physics.js'),
        name: 'Physics',
    },
    // Add more minors as needed
};