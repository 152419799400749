import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import './ProfessorPage.css';


const ProfessorPage = () => {
  const { professorSlug } = useParams();
  const [professorData, setProfessorData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [activeTab, setActiveTab] = useState('coursesTaught');

  useEffect(() => {
    const fetchProfessorData = async () => {
      if (!professorSlug) {
        console.error('Professor slug is undefined');
        setError('Invalid professor name');
        setLoading(false);
        return;
      }

      // Split the professorSlug into firstName and lastName
      const [firstName, lastName] = professorSlug.split('-');

      try {
        console.log(`Fetching data for professor: ${firstName} ${lastName}`);
        const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/professor/${encodeURIComponent(firstName)}/${encodeURIComponent(lastName)}`);
        if (!response.ok) {
          throw new Error(`Failed to fetch professor data: ${response.status} ${response.statusText}`);
        }
        const data = await response.json();
        console.log('Received professor data:', data);
        setProfessorData(processData(data));
      } catch (err) {
        console.error('Error fetching professor data:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProfessorData();
  }, [professorSlug]);

  const getRatingClass = (rating) => {
    if (rating >= 90) return 'rating-excellent';
    if (rating >= 80) return 'rating-good';
    if (rating >= 70) return 'rating-fair';
    if (rating >= 60) return 'rating-mediocre';
    return 'rating-poor';
  };
  

  const processData = (data) => {
    // Calculate average rating across all courses
    const allMedians = data.courses.flatMap(course => 
      course.questions.map(q => q.median)
    );
    const averageRating = (allMedians.reduce((sum, median) => sum + median, 0) / allMedians.length) * 20; // Convert to percentage

    // Process course ratings
    const courseRatings = data.courses.reduce((acc, course) => {
      if (!acc[course.courseCode]) {
        acc[course.courseCode] = {
          timesTaught: 0,
          totalClassSize: 0,
          totalResponseCount: 0,
          totalRating: 0,
          ratingCount: 0
        };
      }
      acc[course.courseCode].timesTaught++;
      acc[course.courseCode].totalClassSize += course.classSize;
      acc[course.courseCode].totalResponseCount += course.responseCount;
      const courseAvgRating = course.questions.reduce((sum, q) => sum + q.median, 0) / course.questions.length;
      acc[course.courseCode].totalRating += courseAvgRating;
      acc[course.courseCode].ratingCount++;
      return acc;
    }, {});

    Object.keys(courseRatings).forEach(code => {
      const cr = courseRatings[code];
      cr.avgClassSize = cr.totalClassSize / cr.timesTaught;
      cr.avgResponseCount = cr.totalResponseCount / cr.timesTaught;
      cr.avgRating = (cr.totalRating / cr.ratingCount) * 20; // Convert to percentage
    });

    return { ...data, averageRating, courseRatings };
  };

  if (loading) {
    return (
      <div className="loading-screen">
        <div className="spinner"></div>
        <p>Loading professor data...</p>
      </div>
    );
  }

  if (error) return <div className="error-message">Error: {error}</div>;
  if (!professorData) return <div className="error-message">No data found for this professor.</div>;

  return (
    <div className="course-page professor-page">
      <header>
        <h1>
          {professorData.lastName.charAt(0).toUpperCase() + professorData.lastName.slice(1).toLowerCase()}, {' '}
          {professorData.firstName.split(' ').map(name => 
            name.charAt(0).toUpperCase() + name.slice(1).toLowerCase()
          ).join(' ')}
        </h1>
        <p className="units-description">{professorData.department}, {professorData.faculty}</p>
        <p className="average-rating">
          Average Rating: <span className={getRatingClass(professorData.averageRating)}>
            {professorData.averageRating.toFixed(1)}%
          </span>
        </p>
      </header>

      <div className="button-container">
        <button
          className={`toggle-button ${activeTab === 'coursesTaught' ? 'active' : ''}`}
          onClick={() => setActiveTab('coursesTaught')}
        >
          Courses Taught
        </button>
        <button
          className={`toggle-button ${activeTab === 'courseRatings' ? 'active' : ''}`}
          onClick={() => setActiveTab('courseRatings')}
        >
          Course Ratings
        </button>
      </div>

      <main>
        {activeTab === 'coursesTaught' && (
          <section>
            <h2>Courses Taught</h2>
            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Course Code</th>
                    <th>Academic Year</th>
                    <th>Section</th>
                    <th>Class Size</th>
                    <th>Response Count</th>
                    <th>Rating</th>
                  </tr>
                </thead>
                <tbody>
                {professorData.courses.map((course, index) => {
                  // Calculate average rating for this specific course offering
                  const courseRating = course.questions && course.questions.length > 0
                    ? (course.questions.reduce((sum, q) => sum + q.median, 0) / course.questions.length) * 20
                    : null;
                    
                  return (
                    <tr key={index}>
                      <td>
                        <Link to={`/course/${course.courseCode}`}>{course.courseCode}</Link>
                      </td>
                      <td>{course.academicYear}</td>
                      <td>{course.section}</td>
                      <td>{course.classSize}</td>
                      <td>{course.responseCount}</td>
                      <td>{courseRating ? `${courseRating.toFixed(1)}%` : 'N/A'}</td>
                    </tr>
                  );
                })}
                </tbody>
              </table>
            </div>
          </section>
        )}

        {activeTab === 'courseRatings' && (
          <section>
            <h2>Course Ratings</h2>
            <div className="table-container">
              <table className="data-table">
                <thead>
                  <tr>
                    <th>Course Code</th>
                    <th>Times Taught</th>
                    <th>Average Class Size</th>
                    <th>Average Response Count</th>
                    <th>Average Rating</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.entries(professorData.courseRatings).map(([courseCode, rating], index) => (
                    <tr key={index}>
                      <td>
                        <Link to={`/course/${courseCode}`}>{courseCode}</Link>
                      </td>
                      <td>{rating.timesTaught}</td>
                      <td>{rating.avgClassSize.toFixed(2)}</td>
                      <td>{rating.avgResponseCount.toFixed(2)}</td>
                      <td>{rating.avgRating.toFixed(2)}%</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </section>
        )}
      </main>

      <footer>
        <p>&copy; 2024 RateMyCourse. All rights reserved.</p>
      </footer>
    </div>
  );
};

export default ProfessorPage;