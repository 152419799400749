import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import './PopularPage.css';

const CustomSelect = ({ options, value, onChange, label }) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (selectRef.current && !selectRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleToggle = (e) => {
    e.stopPropagation();
    setIsOpen(!isOpen);
  };

  const handleOptionClick = (option) => {
    onChange(option);
    setIsOpen(false);
  };

  return (
    <div className="custom-select" ref={selectRef}>
      <label>{label}</label>
      <div 
        className={`custom-select-selected ${isOpen ? 'active' : ''}`}
        onClick={handleToggle}
      >
        {value}
      </div>
      {isOpen && (
        <div className="custom-select-options">
          {options.map((option) => (
            <div
              key={option}
              className="custom-select-option"
              onClick={() => handleOptionClick(option)}
            >
              {option}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const capitalizeName = (name) => {
    if (!name) return '';
    return name
      .split(' ')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
};

const MobileDropdown = ({ selectedType, setSelectedType, isOpen, setIsOpen }) => {
  const handleSelect = (type) => {
    setSelectedType(type);
    setIsOpen(false);
  };

  return (
    <div className="mobile-dropdown">
      <div 
        className={`mobile-dropdown-header ${isOpen ? 'active' : ''}`} 
        onClick={() => setIsOpen(!isOpen)}
      >
        <span>{selectedType === 'courses' ? 'Top Enrolled Courses' : 'Top Enrolled Instructors'}</span>
        <span className={`dropdown-arrow ${isOpen ? 'open' : ''}`}>▼</span>
      </div>
      {isOpen && (
        <div className="mobile-dropdown-content">
          <div 
            className={`mobile-dropdown-item ${selectedType === 'courses' ? 'active' : ''}`}
            onClick={() => handleSelect('courses')}
          >
            Top Enrolled Courses
          </div>
          <div 
            className={`mobile-dropdown-item ${selectedType === 'instructors' ? 'active' : ''}`}
            onClick={() => handleSelect('instructors')}
          >
            Top Enrolled Instructors
          </div>
        </div>
      )}
    </div>
  );
};
  
const Card = ({ item, index, type }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    if (type === 'courses') {
      navigate(`/course/${encodeURIComponent(item.course_code)}`);
    } else {
      // Format: professor/LASTNAME-FIRSTNAME
      const formattedName = `${item.first_name.toUpperCase()}-${item.last_name.toUpperCase()}`;
      navigate(`/professor/${formattedName}`);
    }
  };

  return (
    <li className="card-container" onClick={handleClick}>
      {/* First line: Rank, Name, and Enrollment */}
      <div className="card-header">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <span className="card-rank">#{index + 1}</span>
          {type === 'courses' ? (
            <span className="card-code">{item.course_code}</span>
          ) : (
            <span className="card-name">{`${capitalizeName(item.first_name)} ${capitalizeName(item.last_name)}`}</span>
          )}
        </div>
        <span className="card-enrollment">Enrollment: {item.total_enrollment}</span>
      </div>
      
      {/* Second line: Course Name or Department/Faculty */}
      <div className="card-content">
        {type === 'courses' ? (
          <span className="card-name">{item.course_name}</span>
        ) : (
          <span className="card-department">{`${item.department_name}, ${item.faculty}`}</span>
        )}
      </div>
    </li>
  );
};

const PopularPage = () => {
  const [selectedType, setSelectedType] = useState('courses');
  const [selectedLimit, setSelectedLimit] = useState(10);
  const [selectedYear, setSelectedYear] = useState('All Time');
  const [topEnrolledData, setTopEnrolledData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [mobileDropdownOpen, setMobileDropdownOpen] = useState(false);

  useEffect(() => {
    fetchTopEnrolledData();
  }, [selectedType, selectedLimit, selectedYear]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchTopEnrolledData = async () => {
    setLoading(true);
    try {
      const response = await fetch(`https://normal-ada-course-pilot-f32b853e.koyeb.app/api/top-enrolled?type=${selectedType}&limit=${selectedLimit}${selectedYear !== 'All Time' ? `&year=${selectedYear}` : ''}`);
      
      if (!response.ok) {
        const errorText = await response.text();
        throw new Error(`Network response was not ok: ${response.status} - ${errorText}`);
      }
      const data = await response.json();
      setTopEnrolledData(data);
      setError(null);
    } catch (error) {
      console.error('Error fetching top enrolled data:', error);
      setError('Failed to fetch data. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <div className="popular-page">
      {isMobile ? (
        <MobileDropdown 
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          isOpen={mobileDropdownOpen}
          setIsOpen={setMobileDropdownOpen}
        />
      ) : (
        <div className="sidebar">
          <h2>Popular</h2>
          <button 
            className={selectedType === 'courses' ? 'active' : ''}
            onClick={() => setSelectedType('courses')}
          >
            Top Enrolled Courses
          </button>
          <button 
            className={selectedType === 'instructors' ? 'active' : ''}
            onClick={() => setSelectedType('instructors')}
          >
            Top Enrolled Instructors
          </button>
        </div>
      )}
  
      <div className="content">
        <div className="top-enroll">Top Enrolled {selectedType === 'courses' ? 'Courses' : 'Instructors'}</div>
        <div className="selectors">
          <CustomSelect
            options={[10, 25, 50, 100]}
            value={selectedLimit}
            onChange={(value) => setSelectedLimit(value)}
            label="Count"
          />
  
          <CustomSelect
            options={['All Time', ...Array.from({length: 10}, (_, i) => 2024 - i)]}
            value={selectedYear}
            onChange={(value) => setSelectedYear(value)}
            label="Time Span"
          />
        </div>
  
        <div className="results">
          {loading ? (
            <div className="android-spinner"></div>
          ) : error ? (
            <p className="error">{error}</p>
          ) : (
            <ul>
              {topEnrolledData.map((item, index) => (
                <Card key={index} item={item} index={index} type={selectedType} />
              ))}
            </ul>
          )}
        </div>
      </div>
    </div>
  );
};

export default PopularPage;