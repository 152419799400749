import React from 'react';
import './HomePage.css';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {
  const courseSets = [
    ['PSYCH 104', 'STAT 151', 'PSYCH 105', 'CMPUT 174', 'CMPUT 267', 'BIOL 107', 'CMPUT 355', 'CMPUT 455', 'CMPUT 175', 'MATH 154', 'CMPUT 272', 'PSYCH 275'],
    ['BIOL 108', 'IMIN 200', 'STAT 161', 'CMPUT 291', 'MATH 125', 'PSYCH 223', 'MATH 100', 'CMPUT 101', 'MATH 102', 'CMPUT 201', 'MATH 101', 'CMPUT 204'],
    ['PSYCH 239', 'CMPUT 365', 'BIOL 207', 'PSYCH 282', 'MATH 134', 'ENGG 404', 'MATH 209', 'PSYCH 258', 'PSYCH 241', 'IMIN 371', 'CHE 243', 'MATH 201'],
    ['NUTR 100', 'INTD 101', 'BIOL 208', 'ANTHR 101', 'BUS 101', 'OM 352', 'BIOCH 200', 'CMPUT 301', 'STAT 252', 'STAT 235', 'ACCTG 311', 'CMPUT 366']
  ];

  const colors = [
    '#e6194b', '#f58231', '#ffe119', '#bfef45', '#3cb44b', '#42d4f4', 
    '#4363d8', '#911eb4', '#f032e6'
  ];

  const getColorForCourse = (course) => {
    const hash = course.split('').reduce((acc, char) => acc + char.charCodeAt(0), 0);
    return colors[hash % colors.length];
  };

  const columnHeight = 300;
  const itemHeight = 25;

  const navigate = useNavigate();

  const handleSearchRedirect = () => {
    navigate('/search');
  };

  const handleSearchInstructorsRedirect = () => {
    navigate('/search-instructors');
  };

  const instructors = [
    'Smith', 'Johnson', 'Williams', 'Brown', 'Jones', 'Garcia', 'Miller', 'Davis', 'Rodriguez', 'Martinez',
    'Hernandez', 'Lopez', 'Gonzalez', 'Wilson', 'Anderson', 'Thomas', 'Taylor', 'Moore', 'Jackson', 'Martin',
    'Lee', 'Perez', 'Thompson', 'White', 'Harris', 'Sanchez', 'Clark', 'Ramirez', 'Lewis', 'Robinson',
    'Walker', 'Young', 'Allen', 'King', 'Wright', 'Scott', 'Torres', 'Nguyen', 'Hill', 'Flores',
    'Green', 'Adams', 'Nelson', 'Baker', 'Hall', 'Rivera', 'Campbell', 'Mitchell'
  ];

  const instructorPositions = [
    {x: 50, y: 200, rotate: 0}, {x: 150, y: 100, rotate: 90}, {x: 250, y: 300, rotate: 0},
    {x: 100, y: 50, rotate: 0}, {x: 200, y: 250, rotate: 90}, {x: 300, y: 150, rotate: 0},
    {x: 75, y: 300, rotate: 90}, {x: 175, y: 200, rotate: 0}, {x: 275, y: 100, rotate: 90},
    {x: 125, y: 150, rotate: 0}, {x: 225, y: 50, rotate: 90}, {x: 325, y: 250, rotate: 0},
    {x: 50, y: 100, rotate: 90}, {x: 150, y: 300, rotate: 0}, {x: 250, y: 200, rotate: 90},
    {x: 100, y: 250, rotate: 0}, {x: 200, y: 150, rotate: 90}, {x: 300, y: 50, rotate: 0},
    {x: 75, y: 200, rotate: 90}, {x: 175, y: 100, rotate: 0}, {x: 275, y: 300, rotate: 90},
    {x: 125, y: 50, rotate: 0}, {x: 225, y: 250, rotate: 90}, {x: 325, y: 150, rotate: 0},
    {x: 50, y: 300, rotate: 90}, {x: 150, y: 200, rotate: 0}, {x: 250, y: 100, rotate: 90},
    {x: 100, y: 150, rotate: 0}, {x: 200, y: 50, rotate: 90}, {x: 300, y: 250, rotate: 0},
    {x: 75, y: 100, rotate: 90}, {x: 175, y: 300, rotate: 0}, {x: 275, y: 200, rotate: 90},
    {x: 125, y: 250, rotate: 0}, {x: 225, y: 150, rotate: 90}, {x: 325, y: 50, rotate: 0},
    {x: 50, y: 200, rotate: 90}, {x: 150, y: 100, rotate: 0}, {x: 250, y: 300, rotate: 90},
    {x: 100, y: 50, rotate: 0}, {x: 200, y: 250, rotate: 90}, {x: 300, y: 150, rotate: 0},
    {x: 75, y: 300, rotate: 90}, {x: 175, y: 200, rotate: 0}, {x: 275, y: 100, rotate: 90},
    {x: 125, y: 150, rotate: 0}, {x: 225, y: 50, rotate: 90}, {x: 325, y: 250, rotate: 0}
  ];

  return (
    <div className="home-page-wrapper">
      <div className="content">
        <div className="left-section">
          <h1 className="heading">Browse through all courses.</h1>
          <p className="subtitle">
            Plan your degree with the knowledge to succeed. With data from Fall 2015 to present, 
            we have information about every course and section taught at UofA.
          </p>

          <div className="stats-section">
            <div className="stat-circle" style={{ backgroundColor: '#1dce17' }}>
              <p className="stat-number">120k</p>
            </div>
            <p className="stat-label">Sections</p>

            <div className="stat-circle" style={{ backgroundColor: '#963929' }}>
              <p className="stat-number">8.8k</p>
            </div>
            <p className="stat-label">Courses</p>
          </div>

          <button className="search-courses-button" onClick={handleSearchRedirect}>Search Courses</button>
        </div>

        <div className="right-section">
          <svg viewBox={`0 0 400 ${columnHeight}`} xmlns="http://www.w3.org/2000/svg">
            {courseSets.map((courses, colIndex) => (
              <g key={colIndex} className={`column column-${colIndex}`}>
                {[...courses, ...courses, ...courses].map((course, index) => {
                  const [code, number] = course.split(' ');
                  const color = getColorForCourse(course);
                  return (
                    <text key={`${colIndex}-${index}`} x={colIndex * 100} y={index * itemHeight} className="course-text">
                      <tspan className="course-item" style={{fill: color}}>
                        {code.padEnd(6)}
                      </tspan>
                      <tspan dx="5" className="course-item" style={{fill: color}}>
                        {number}
                      </tspan>
                    </text>
                  );
                })}
              </g>
            ))}
          </svg>
          <p className="most-enrolled-courses-text">Top 48 most enrolled courses at UAlberta.</p>
        </div>
      </div>
      
      <div className="content instructor-content">
        <div className="instructor-text-content">
          <h2 className="instructor-heading">Get to know your instructors</h2>
          <p className="instructor-subtitle">
            Gain insight by learning more about *who* your professors are. View previously taught courses and compare grades that their students received.
          </p>
          <div className="stats-section">
            <div className="stat-circle" style={{ backgroundColor: '#91cdff' }}>
              <p className="stat-number">2.5k</p>
            </div>
            <p className="stat-label">Instructors</p>

            <div className="stat-circle" style={{ backgroundColor: '#b4529c' }}>
              <p className="stat-number">120</p>
            </div>
            <p className="stat-label">Subjects</p>
          </div>
          <button className="search-instructors-button" onClick={handleSearchInstructorsRedirect}>Search Instructors</button>
        </div>
        <div className="instructor-svg-container">
          <svg viewBox="0 0 400 400" xmlns="http://www.w3.org/2000/svg" className="instructor-svg">
            {instructors.map((instructor, index) => {
              const fontSize = 48 - index;
              const color = colors[index % colors.length];
              const { x, y, rotate } = instructorPositions[index];
              return (
                <text
                  key={index}
                  x={x}
                  y={y}
                  fontSize={fontSize}
                  fill={color}
                  fontFamily="Arial, sans-serif"
                  textAnchor="middle"
                  transform={`rotate(${rotate}, ${x}, ${y})`}
                >
                  {instructor}
                </text>
              );
            })}
          </svg>
          <p className="instructor-text">Top 48 most popular instructors at UAlberta.</p>
        </div>
      </div>
    </div>
  );
};

export default HomePage;