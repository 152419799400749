// DataUpload.js

import React, { useState } from 'react';
import axios from 'axios';
import './DataUpload.css';

const DataUpload = ({ apiUrl }) => {
  // State for individual data upload form
  const [formData, setFormData] = useState({
    academicYear: '',
    courseCode: '',
    courseType: '',
    section: '',
    instructorFirstName: '',
    instructorLastName: '',
    department: '',
    faculty: '',
    classSize: '',
    responseCount: '',
    processDate: '',
    questions: []
  });

  // State for bulk uploads: an array of upload objects
  const [bulkUploads, setBulkUploads] = useState([
    { id: Date.now(), file: null, progress: 0, message: '' }
  ]);

  const [message, setMessage] = useState(''); // General message
  const [uploading, setUploading] = useState(false); // Uploading state for individual upload

  // Handler for input changes in individual upload form
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  // Add a new question to the form
  const addQuestion = () => {
    setFormData(prevState => ({
      ...prevState,
      questions: [...prevState.questions, {}]
    }));
  };

  // Handler for question input changes
  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...formData.questions];
    if (!updatedQuestions[index]) {
      updatedQuestions[index] = {};
    }
    updatedQuestions[index][field] = value;
    setFormData(prevState => ({
      ...prevState,
      questions: updatedQuestions
    }));
  };

  // Submit handler for individual upload
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Validate required fields (optional but recommended)
    // Add your validation logic here

    // Map non-LEC course types to LABS before sending
    const modifiedFormData = {
      ...formData,
      courseType: formData.courseType.toUpperCase() === 'LEC' ? 'LEC' : 'LABS'
    };

    setUploading(true);
    setMessage('');

    try {
      await axios.post(apiUrl, modifiedFormData);
      setMessage('Individual data uploaded successfully');
      // Reset form (optional)
      setFormData({
        academicYear: '',
        courseCode: '',
        courseType: '',
        section: '',
        instructorFirstName: '',
        instructorLastName: '',
        department: '',
        faculty: '',
        classSize: '',
        responseCount: '',
        processDate: '',
        questions: []
      });
    } catch (error) {
      console.error('Error uploading individual data:', error);
      setMessage('Error uploading individual data: ' + (error.response ? error.response.data : error.message));
    } finally {
      setUploading(false);
    }
  };

  // Handler to add a new bulk upload input
  const handleAddBulkUpload = () => {
    setBulkUploads([...bulkUploads, { id: Date.now(), file: null, progress: 0, message: '' }]);
  };

  // Handler to remove a bulk upload input
  const handleRemoveBulkUpload = (id) => {
    setBulkUploads(bulkUploads.filter(upload => upload.id !== id));
  };

  // Handler for file input change in bulk uploads
  const handleBulkFileChange = (id, e) => {
    const newBulkUploads = bulkUploads.map(upload => {
      if (upload.id === id) {
        return { ...upload, file: e.target.files[0], progress: 0, message: '' };
      }
      return upload;
    });
    setBulkUploads(newBulkUploads);
  };

  // Handler for bulk upload submission
  const handleBulkUpload = async () => {
    setMessage('');
    let allSuccess = true;

    for (const upload of bulkUploads) {
      if (!upload.file) {
        // Skip if no file is selected
        continue;
      }

      try {
        // Read the file content
        const fileContent = await readFileAsText(upload.file);
        const jsonData = JSON.parse(fileContent);

        // Ensure jsonData is an array
        if (!Array.isArray(jsonData)) {
          throw new Error('Bulk JSON file must be an array of objects');
        }

        // Split the data into chunks of approximately 4000 lines
        const chunkSize = 4000;
        const totalChunks = Math.ceil(jsonData.length / chunkSize);

        for (let i = 0; i < totalChunks; i++) {
          const chunk = jsonData.slice(i * chunkSize, (i + 1) * chunkSize);
          await axios.post(apiUrl, chunk);
          // Update progress
          const progress = Math.round(((i + 1) / totalChunks) * 100);
          updateBulkUploadProgress(upload.id, progress);
        }

        // Update message for this upload
        updateBulkUploadMessage(upload.id, 'Bulk data uploaded successfully');
      } catch (error) {
        console.error(`Error uploading bulk data for file ${upload.file.name}:`, error);
        updateBulkUploadMessage(upload.id, 'Error uploading bulk data: ' + (error.response ? error.response.data : error.message));
        allSuccess = false;
      }
    }

    if (allSuccess) {
      setMessage('All bulk uploads completed successfully');
    } else {
      setMessage('Some bulk uploads failed. Check individual messages.');
    }
  };

  // Utility function to read a file as text
  const readFileAsText = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (e) => {
        resolve(e.target.result);
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsText(file);
    });
  };

  // Update progress for a specific bulk upload
  const updateBulkUploadProgress = (id, progress) => {
    setBulkUploads(prevUploads =>
      prevUploads.map(upload => {
        if (upload.id === id) {
          return { ...upload, progress };
        }
        return upload;
      })
    );
  };

  // Update message for a specific bulk upload
  const updateBulkUploadMessage = (id, message) => {
    setBulkUploads(prevUploads =>
      prevUploads.map(upload => {
        if (upload.id === id) {
          return { ...upload, message };
        }
        return upload;
      })
    );
  };

  return (
    <div className="data-upload-container">
      {/* Individual Data Upload Section */}
      <h2>Individual Data Upload</h2>
      <form onSubmit={handleSubmit}>
        {/* Input fields for formData */}
        <input
          type="text"
          name="academicYear"
          placeholder="Academic Year"
          value={formData.academicYear}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="courseCode"
          placeholder="Course Code"
          value={formData.courseCode}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="courseType"
          placeholder="Course Type (LEC/LABS)"
          value={formData.courseType}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="section"
          placeholder="Section"
          value={formData.section}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="instructorFirstName"
          placeholder="Instructor First Name"
          value={formData.instructorFirstName}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="instructorLastName"
          placeholder="Instructor Last Name"
          value={formData.instructorLastName}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="department"
          placeholder="Department"
          value={formData.department}
          onChange={handleInputChange}
          required
        />
        <input
          type="text"
          name="faculty"
          placeholder="Faculty"
          value={formData.faculty}
          onChange={handleInputChange}
          required
        />
        <input
          type="number"
          name="classSize"
          placeholder="Class Size"
          value={formData.classSize}
          onChange={handleInputChange}
          required
        />
        <input
          type="number"
          name="responseCount"
          placeholder="Response Count"
          value={formData.responseCount}
          onChange={handleInputChange}
          required
        />
        <input
          type="date"
          name="processDate"
          placeholder="Process Date"
          value={formData.processDate}
          onChange={handleInputChange}
          required
        />

        <h3>Questions</h3>
        {formData.questions.map((question, index) => (
          <div key={index} className="question-container">
            <input
              type="text"
              placeholder="Question Text"
              value={question.text || ''}
              onChange={(e) => handleQuestionChange(index, 'text', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Strongly Disagree"
              value={question.stronglyDisagree || ''}
              onChange={(e) => handleQuestionChange(index, 'stronglyDisagree', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Disagree"
              value={question.disagree || ''}
              onChange={(e) => handleQuestionChange(index, 'disagree', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Neither"
              value={question.neither || ''}
              onChange={(e) => handleQuestionChange(index, 'neither', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Agree"
              value={question.agree || ''}
              onChange={(e) => handleQuestionChange(index, 'agree', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Strongly Agree"
              value={question.stronglyAgree || ''}
              onChange={(e) => handleQuestionChange(index, 'stronglyAgree', e.target.value)}
              required
            />
            <input
              type="number"
              placeholder="Median"
              value={question.median || ''}
              onChange={(e) => handleQuestionChange(index, 'median', e.target.value)}
              required
            />
          </div>
        ))}
        <button type="button" onClick={addQuestion} disabled={uploading}>Add Question</button>

        <button type="submit" disabled={uploading}>
          {uploading ? 'Uploading...' : 'Submit'}
        </button>
      </form>

      {/* Bulk Data Upload Section */}
      <h2>Bulk Upload</h2>

      <div className="bulk-upload-section">
        {bulkUploads.map((upload, index) => (
          <div key={upload.id} className="bulk-upload-input">
            <input
              type="file"
              accept=".json"
              onChange={(e) => handleBulkFileChange(upload.id, e)}
              disabled={uploading}
            />
            {bulkUploads.length > 1 && (
              <button
                type="button"
                onClick={() => handleRemoveBulkUpload(upload.id)}
                disabled={uploading}
                className="remove-upload-button"
              >
                Remove
              </button>
            )}
            {/* Progress Bar */}
            {upload.progress > 0 && (
              <div className="progress-bar-container">
                <div className="progress-bar" style={{ width: `${upload.progress}%` }}>
                  {upload.progress}%
                </div>
              </div>
            )}
            {/* Message */}
            {upload.message && <p className="upload-message">{upload.message}</p>}
          </div>
        ))}

        <button type="button" onClick={handleAddBulkUpload} disabled={uploading} className="add-upload-button">
          Add Upload
        </button>

        <button type="button" onClick={handleBulkUpload} disabled={uploading} className="upload-all-button">
          {uploading ? 'Uploading...' : 'Upload All Bulk Data'}
        </button>
      </div>

      {/* General Message */}
      {message && <p className="general-message">{message}</p>}
    </div>
  );
};

export default DataUpload;
