// CoursePilot/src/faculty_of_science/JuniorCore.js

import React, { useState, useEffect } from 'react';
import './junior_requirements.css';

const JuniorCore = ({ completedCourses, removeCourse }) => {
    const [juniorCoreRequirements, setJuniorCoreRequirements] = useState({});
    const [juniorCoreCourses, setJuniorCoreCourses] = useState({
        english: [],
        physicalSciences: [],
        mathSciences: [],
        labSciences: []
    });

    useEffect(() => {
        fetchJuniorCoreRequirements();
    }, []);

    useEffect(() => {
        updateJuniorCoreCourses();
    }, [completedCourses, juniorCoreRequirements]);

    const fetchJuniorCoreRequirements = async () => {
        try {
            const response = await fetch('https://normal-ada-course-pilot-f32b853e.koyeb.app/api/junior-core-requirements');
            if (!response.ok) throw new Error('Failed to fetch Junior Core requirements');
            const data = await response.json();
            setJuniorCoreRequirements(data);
        } catch (error) {
            console.error('Error fetching Junior Core requirements:', error);
        }
    };

    const updateJuniorCoreCourses = () => {
        const newJuniorCoreCourses = {
            english: [],
            physicalSciences: [],
            mathSciences: [],
            labSciences: []
        };

        completedCourses.forEach(course => {
            const { course_letter, course_number } = course;
            const category = Object.keys(juniorCoreRequirements).find(cat =>
                juniorCoreRequirements[cat].some(c =>
                    c.course_letter === course_letter && c.course_number === course_number
                )
            );

            if (category) {
                switch (category) {
                    case 'English':
                        if (newJuniorCoreCourses.english.length < 2) {
                            newJuniorCoreCourses.english.push(course);
                        }
                        break;
                    case 'Physical Sciences':
                        if (newJuniorCoreCourses.physicalSciences.length < 2) {
                            newJuniorCoreCourses.physicalSciences.push(course);
                        }
                        break;
                    case 'Mathematical Sciences':
                        if (newJuniorCoreCourses.mathSciences.length < 2) {
                            newJuniorCoreCourses.mathSciences.push(course);
                        }
                        break;
                    case 'Laboratory Sciences':
                        if (newJuniorCoreCourses.labSciences.length < 2) {
                            newJuniorCoreCourses.labSciences.push(course);
                        }
                        break;
                    default:
                        break;
                }
            }
        });

        setJuniorCoreCourses(newJuniorCoreCourses);
    };

    const removeCourseFromJuniorCore = (category, courseToRemove) => {
        removeCourse(courseToRemove.course_letter, courseToRemove.course_number);
    };

    const renderJuniorCoreCategory = (categoryName, courses) => (
        <div className="category">
            <h4>{categoryName}</h4>
            <p>{courses.length} / 2 completed</p>
            <div className="course-tags">
                {courses.map((course, index) => (
                    <div key={index} className="course-tag">
                        {course.course_letter} {course.course_number}
                        <button onClick={() => removeCourseFromJuniorCore(categoryName, course)}>×</button>
                    </div>
                ))}
            </div>
        </div>
    );

    const juniorCoreSatisfied = 
        juniorCoreCourses.english.length >= 2 &&
        juniorCoreCourses.physicalSciences.length >= 2 &&
        juniorCoreCourses.mathSciences.length >= 2 &&
        juniorCoreCourses.labSciences.length >= 2;

    return (
        <div className="requirement">
            <h3>Junior Core</h3>
            <p>Status: {juniorCoreSatisfied ? '✅ Satisfied' : '❌ Not Satisfied'}</p>
            <div className="junior-core-categories">
                {renderJuniorCoreCategory("English", juniorCoreCourses.english)}
                {renderJuniorCoreCategory("Physical Science", juniorCoreCourses.physicalSciences)}
                {renderJuniorCoreCategory("Math Science", juniorCoreCourses.mathSciences)}
                {renderJuniorCoreCategory("Laboratory Science", juniorCoreCourses.labSciences)}
            </div>
        </div>
    );
};

export default JuniorCore;